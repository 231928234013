import { Select } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    Col,
    Row,
    Breadcrumb,
    Modal,
    Button,
    ProgressBar,
    Form,
    FormGroup,
    InputGroup,
    Nav,
    OverlayTrigger,
    Tooltip,
  } from "react-bootstrap";
  import {
    MapContainer,
    TileLayer,
    Popup,
    CircleMarker,
    Polyline,
    Rectangle,
    Polygon,
    Circle,
    Marker,
    useMapEvent,
    useMap,
  } from "react-leaflet";
  
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
    CFormSelect,
  } from "@coreui/react";
  import Dropzone from "react-dropzone";
  import { Link } from "react-router-dom";
import { IJoc360 } from "../Interfaces/Interfaces";
import { API_URL } from "../../assets/Constants/constants";



export const Modal360 = (show :any ,setShow:any ) => {
    const [Fileuploader, setFileuploader] = React.useState([]);
    const [Search, setSearch] = React.useState<any>("");
    const options :any = [
        { value: "1", label: "Restaurant" },
        { value: "2", label: "Culture" },
        { value: "3", label: "Festival" },
        { value: "4", label: "Hotel" },
        { value: "5", label: "Info" },
        { value: "6", label: "Monument" },
        { value: "7", label: "Parking" },
        { value: "8", label: "Others" },
      ];

    const [form,setForm] = React.useState<IJoc360>({
      idJoc360: null,
      idRecompensa: null,
      idTipus: 1,
      imatgeMiniatura: '',
      preguntaQuizz: '',
      respostaQuizz: '',
      titol: '',
      url: '',
  });

  const sendData = () => { 
    console.log(form);
    fetch(API_URL+'/joc360/create', {
     method:'POST',
      headers: {
        "Content-Type": "application/json",
        "Bearer": "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify(form)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if(data.code == 1){  setShow(true); }
    })
    .catch(error => {
      console.error(error);
    });
  };

    
    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      }

    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        setFileuploader(files);
      }  

    return (
        <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header
          closeButton
          style={{
            width: "50vw",
            maxHeight: "90vh",
            alignSelf: "center",
            backgroundColor: "white",
          }}
        >
          <Modal.Title>Add new game</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            width: "50vw",
            maxHeight: "100vh",
            alignSelf: "center",
            backgroundColor: "white",
          }}
        >
          <Row>
            {/* Segona columna */}

            <Col md={6}>
              <Card.Body>
                    <label className="form-label">Icon</label>
                    {Fileuploader.length == 0 && Fileuploader != null ? (
                      <Dropzone 
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone dz-clickable w-100 h-25 d-flex justify-content-center">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <div className="justify-content-center mt-1 dropzoneicon ">
                                <i className="mdi mdi-apple-mobileme justify-content-center mt-4 mb-1"></i>
                              </div>
                              <p style={{ color: "#9393b5" }}>
                                Drop files here or click to upload.
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div className="list-unstyled mb-0" id="file-previews">
                        {Fileuploader.map((f: any, i: any) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  {/* Close delete button */}
                                  <Col className="col-auto">
                                    <button
                                      type="button"
                                      className="btn btn-link font-18 text-muted"
                                      onClick={() => {
                                        let newFileList = [...Fileuploader];
                                        newFileList.splice(i, 1);
                                        setFileuploader(newFileList);
                                      }}
                                    >
                                      <i className="dripicons-cross">x</i>
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    )}

                    <div className="form-group">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Game title"
                        onChange={(e) => {setForm({...form, titol: e.target.value})}}
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">Web</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Web"
                        onChange={(e) => {setForm({...form, url: e.target.value})}}
                      />
                    </div>
              </Card.Body>
            </Col>
            <Col md={6}>
              

              <div>
                  <div className="form-group">
                      <label className="form-label">Question</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter a question"
                        onChange={(e) => {setForm({...form, preguntaQuizz: e.target.value})}}
                      />
                    </div>
              </div>
              <label className="form-label">Answer</label>
              <input 
                type="text"
                className="form-control"
                maxLength={10} placeholder="Enter a Answer (Max 10 char)"
                onChange={(e) => {setForm({...form, respostaQuizz: e.target.value})}}
              />
            </Col>
            <Col
              sm={12}
              className="col-12 justify-content-right padding-bottom-20"
            >
              {/* div flex end */}
              <div className="h-100 d-flex align-items-end justify-content-end">
                <div className="px-4">
                  <Button
                    variant="primary"
                    className="btn btn-primary   d-flex align-items-end justify-content-end  "
                    onClick={() => {
                     sendData();
                    }}
                  >
                    Create Game
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
}