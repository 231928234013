import { Select } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    Col,
    Row,
    Breadcrumb,
    Modal,
    Button,
    ProgressBar,
    Form,
    FormGroup,
    InputGroup,
    Nav,
    OverlayTrigger,
    Tooltip,
  } from "react-bootstrap";
  import {
    MapContainer,
    TileLayer,
    Popup,
    CircleMarker,
    Polyline,
    Rectangle,
    Polygon,
    Circle,
    Marker,
    useMapEvent,
    useMap,
  } from "react-leaflet";
  
import {
    CForm,
    CCol,
    CFormLabel,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CButton,
    CFormCheck,
    CFormSelect,
  } from "@coreui/react";
import { ACTIVITY_LIST } from "../../Data/dataActivity";

export const ModalActivity = (show :any ,setShow:any , activity:any,setActivity:any) => {

    const [currentActivity, setcurrentActivity] = useState<any>({

    });

    return (
        <Modal show={show} onHide={setShow} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add new activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            
            <Col xl={12} lg={12} md={12} xs={12}>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Activity list</h4>
                  <div className="input-group mb-3">
                    <CCol md={12} className="position-relative ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Activity
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormSelect
                          className="form-control"
                          id="validationTooltipUsername"
                          aria-describedby="inputGroupPrepend"
                          required
                        >
                          <option value="">Select activity</option>
                          {ACTIVITY_LIST.map((item:any) => {
                            return (
                              <option value={item.id} key={item.id}>
                               <h4>{item.titol}</h4>
                              </option>
                            );
                          })}
                        </CFormSelect>
                      </CInputGroup>
                    </CCol>

                    <CCol md={6} className="position-relative ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Time
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormInput
                          type="time"
                          className="form-control"
                          id="validationTooltipUsername"
                          aria-describedby="inputGroupPrepend"
                          required
                        />
                      </CInputGroup>
                    </CCol>    
                    <CCol md={6} className="position-relative ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Quantity
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormInput
                          type="number"
                          className="form-control"
                          id="validationTooltipUsername"
                          aria-describedby="inputGroupPrepend"
                          defaultValue={1}
                          required
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol md={3}></CCol>
                    <CCol md={6} className="position-relative justify-content-center ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Day
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormSelect
                            className="form-control"
                            id="validationTooltipUsername"
                            aria-describedby="inputGroupPrepend"
                            required
                        >
                            <option value="">Select day</option>
                            <option value="1">Day 1</option>
                            <option value="2">Day 2</option>
                            <option value="3">Day 3</option>
                            <option value="4">Day 4</option>
                            <option value="5">Day 5</option>
                            <option value="6">Day 6</option>
                            <option value="7">Day 7</option>
                        </CFormSelect>
                      </CInputGroup>
                    </CCol>    
                  </div>
                </div>
                <Button variant="primary" type="submit"
                    onClick={() => {
                        setActivity(activity);
                        setShow(false);
                    }}
                >
                      Add
                    </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
}
