import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Modal,
  Button,
  ProgressBar,
  Form,
  FormGroup,
  InputGroup,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Polyline,
  Rectangle,
  Polygon,
  Circle,
  Marker,
  useMapEvent,
  useMap,
} from "react-leaflet";
import Select from "react-select";

import { addCoordinateTransforms, fromLonLat } from "ol/proj";
import { icon, LatLng, latLng, map } from "leaflet";
import SearchBox from "./SearchBox";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "../../../index.scss";
import src from "react-select/dist/declarations/src";
import { COLUMNS, DATATABLE, GlobalFilter } from "../../../Data/dataCostumers";
import Scrollbars from "react-custom-scrollbars";
// import { ACTIVITY_LIST } from "../../../Data/dataActivity";
import { API_URL } from "../../../assets/Constants/constants";
import { ActivityModal } from "../../Modals/activityModal";

const LeafletMaps = () => {
  const [mapPosition, setMapPosition] = React.useState(
    latLng([42.12033,2.452541])
  );
  const [markerPosition, setMarkerPosition] = React.useState(
    latLng([42.12033,2.452541])
  );
  const position: any = [51.505, -0.09];
  const redOptions: any = { color: "red" };
  const center: any = [51.51, -0.12];
  const [Fileuploader, setFileuploader] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [Search, setSearch] = React.useState<any>("");
  const [ACTIVITY_LIST, setACTIVITY_LIST] = React.useState<any>([]);
  const [currentActivity, setCurrentActivity] = React.useState<any>({latitud : 42.12033 , longitud : 2.452541 , nom :"Loading..." });;


  useEffect(() => {
    getAllData();
  }, []);

  // Change map position when marker position change




    function getAllData() {
      fetch(API_URL + "/activitat", {
         method: "GET",
         headers: {
           "Content-Type": "application/json",
           "Bearer": "Bearer " + localStorage.getItem("token"),
         },
       })
         .then((response) => response.json())
         .then((data) => {
           setACTIVITY_LIST(data.rows);
           setCurrentActivity(data.rows[0])
           setMapPosition(latLng([data.rows[0].latitud,data.rows[0].longitud]))
          //  return data;
         }
         )
         .catch((error) => {
           console.error("Error:", error);
           return { code : 2 ,error: error };
         }
         );
       }
  
  const RecenterAutomatically = ({ lat, lng }: any) => {
    const map = useMap();
    useEffect(() => {
      if (
        markerPosition.lat == mapPosition.lat &&
        markerPosition.lng == mapPosition.lng 
        // && lat != undefined && lng != undefined
      ) {
       map.setView([lat, lng], map.getZoom());
      }
    }, [lat, lng]);
    return null;
  };



  // Llista de activitats
  function renderActivites(data: any) {
    return ACTIVITY_LIST.map((item: any) => {
      return (
        <button
          className="main-contact-item w-100"
          key={item.id}
          onClick={() => setCurrentActivity(item) }
        >
          <div className="main-img-user online">
            <img alt="avatar" src={require("../../../assets/img/point.png")} />
          </div>
          <div className="main-contact-body">
            <h5>{item.nom}</h5>
          </div>
        </button>
      );
    });
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <h4 className="content-title mb-2">Activity</h4>
        </div>
        <div className="justify-content-center mt-2">
          <Button
            variant="primary"
            className="btn btn-primary   d-flex align-items-end justify-content-end  "
            onClick={() => {
              
              setShow(true);
            }}
          >
            + Create Activity
          </Button>
        </div>
      </div>
      
      {/* <!-- /breadcrumb --> */}
      <div className="my-auto breadcrumb-right">
        <div className="p-1">
          <Row>
            {/* Row 100 width */}
            <Col sm={12} lg={5} xl={4} xxl={3}>
              <Card className="custom-card">
                <div className="">
                  <div className="main-content-contacts pt-0">
                    <div className="main-content-left main-content-left-contacts slid1">
                      <Nav
                        defaultActiveKey="link-1"
                        className="nav main-nav-line  border-bottom-0 main-nav-line-chat  p-3"
                      >
                        <Nav.Item></Nav.Item>
                      </Nav>
                      <Scrollbars style={{ height: 750 }}>
                        <div
                          className="main-contacts-list"
                          id="mainContactList"
                        >
                          {renderActivites(ACTIVITY_LIST)}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={12} lg={7} xl={8} xxl={9}>
              {/* Activity form */}
              <div className="">
                <Card className="custom-card">
                  <Card.Body>
                    <Row>
                      <Col sm={12} lg={7} xl={8} xxl={9}>
                        <div className="">
                          <Link
                            className="main-header-arrow"
                            to="#"
                            id="ChatBodyHide"
                          >
                            <i className="icon ion-md-arrow-back"></i>
                          </Link>
                          <div className="main-content-body main-content-body-contacts card custom-card">
                            <div className="main-contact-info-header pt-3">
                              <div className="media">
                                <div className="media-body">
                                  <h2>{currentActivity.nom}</h2>
                                </div>
                              </div>
                              <div className="main-contact-action btn-list ">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Edit Customer</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-primary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Edit Profile"
                                  >
                                    <i className="fe fe-edit"></i>
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Delete Customer</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-secondary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Delete Profile"
                                  >
                                    <i className="fe fe-trash-2"></i>
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="main-contact-info-body">
                          {/* Mapa Vista previa */}
                          <label className="form-label">Map</label>
                          <MapContainer
                            className="mapleaflet ht-200"
                            id="mapa"
                            
                            style={{ height: "40vh", width: "100%" }}
                            center={latLng(
                              currentActivity.latitud,
                              currentActivity.longitud
                            )}
                            zoom={5}
                          >
                            <>
                            {console.log(currentActivity)}
                            </>
                                <RecenterAutomatically
                                lat={currentActivity.latitud}
                                lng={currentActivity.longitud}
                              />
                           
                            {/* when markerposition change move map to this position */}
                            <Marker
                              position={ currentActivity.latitud != undefined && currentActivity.longitud != undefined ? latLng(
                                currentActivity.latitud,
                                currentActivity.longitud
                              ): latLng(41.3851, 2.1734)}
                              draggable={false}
                              icon={icon({
                                iconUrl: require("../../../assets/img/point.png"),
                                iconSize: [40, 40],
                              })}
                            />
                            <TileLayer url="https://geoserveis.icgc.cat/styles/icgc_mapa_estandard/{z}/{x}/{y}.png" />
                          </MapContainer>
                        </div>
                      </Col>
                      <Col lg={6}>
                        {/* Latitud longitud */}
                        <Row>
                          <Col lg={3}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Latitude</label>
                              <p>{currentActivity.latitud}</p>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Longitude</label>
                              <p>{currentActivity.longitud}</p>
                            </div>
                          </Col>
                          <Col lg={6}>
                            {/* Adreça */}
                            <div className="main-contact-info-body">
                              <label className="form-label">Address</label>
                              <p>{currentActivity.adreca}</p>
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Description</label>
                              <p>{currentActivity.descripcio}</p>
                            </div>
                          </Col>
                          <Col lg={4}>
                            {/* Telf */}
                            <div className="main-contact-info-body">
                              <label className="form-label">Phone</label>
                              <p>{currentActivity.telefon}</p>
                            </div>
                          </Col>
                          <Col lg={3}>
                            {/* Type */}
                            <div className="main-contact-info-body">
                              <label className="form-label">Type</label>
                              <p>{currentActivity.tipusActivitat}</p>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Price</label>
                              <p>{currentActivity.preu}</p>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Web</label>
                              <a href={currentActivity.web}> {currentActivity.web}</a>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {ActivityModal(show,setShow)}

    </div>
  );
};

LeafletMaps.propTypes = {};

LeafletMaps.defaultProps = {};

export default LeafletMaps;
