import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
  Modal,
  Form,
  FormGroup,
} from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";

import {
  CForm,
  CCol,
  CFormLabel,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
  CFormSelect,
} from "@coreui/react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import {
  CLINETDATA,
  COLUMNS,
  DATATABLE,
  GlobalFilter,
} from "../../Data/dataCostumers";
import { optionCountry, optionGroups } from "../../Data/dataCostumers";
import { Icon } from "@material-ui/core";
import Select from "react-select";
import { ACTIVITY_LIST } from "../../Data/dataActivity";
import { IUser } from "../Interfaces/Interfaces";

export default function ManageCustomers() {
  const tableInstance = useTable(
    {
      columns: COLUMNS,
      data: DATATABLE,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [showActivity, setShowActivity] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleActivity = () => setShowActivity(false);
  const CUSTOMERSDATA : IUser[] = CLINETDATA;
  //Get custom data from select country
  const [Custom, setCustom] = useState("");
  const handleOnchangeCustom = () => {
    setCustom(Custom);
  };

  var [currentCostumer, setCurrentCostumer] = useState(CUSTOMERSDATA[0]);

  const {
    getTableProps, // table props from react-table
    headerGroups, // headerGroups, if your table has groupings
    getTableBodyProps, // table body props from react-table
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    setGlobalFilter,
    page, // use, page or rows
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  }: any = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  function renderCustomers(data: any) {
    return data.map((item: any) => {
      return (
        <button
          className="main-contact-item w-100"
          key={item.Mail}
          onClick={() => setCurrentCostumer(item)}
        >
          <div className="main-img-user online">
            <img alt="avatar" src={require("../../assets/img/faces/3.jpg")} />
          </div>
          <div className="main-contact-body">
            <h5>{item.Nom}</h5>
            <span className="phone">
              <h6 style={{ fontSize: ".8rem" }}>{item.Mail}</h6>
            </span>
          </div>
        </button>
      );
    });
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Customers</span>
        </div>
        <div className="justify-content-center mt-2">
          <Button
            variant=""
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setShow(true);
            }}
          >
            <i className="fe fe-plus me-1"></i> Add New Customer
          </Button>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      {/* Modal Add Activity */}
      <Modal show={showActivity} onHide={handleActivity} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add new activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12} xs={12}>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Activity list</h4>
                  <div className="input-group mb-3">
                    <CCol md={12} className="position-relative ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Activity
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormSelect
                          className="form-control"
                          id="validationTooltipUsername"
                          aria-describedby="inputGroupPrepend"
                          required
                        >
                          <option value="">Select activity</option>
                          {ACTIVITY_LIST.map((item:any) => {
                            return (
                              <option value={item.id} key={item.id}>
                               <h4>{item.titol}</h4>
                              </option>
                            );
                          })}
                        </CFormSelect>
                      </CInputGroup>
                    </CCol>
                    <CCol md={12} className="position-relative ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Date
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormInput
                          type="date"
                          className="form-control"
                          id="validationTooltipUsername"
                          aria-describedby="inputGroupPrepend"
                          required
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol md={6} className="position-relative ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Time
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormInput
                          type="time"
                          className="form-control"
                          id="validationTooltipUsername"
                          aria-describedby="inputGroupPrepend"
                          required
                        />
                      </CInputGroup>
                    </CCol>    
                    <CCol md={6} className="position-relative ">
                      <CFormLabel htmlFor="validationTooltipUsername">
                        Quantity
                      </CFormLabel>
                      <CInputGroup className="has-validation">
                        <CFormInput
                          type="number"
                          className="form-control"
                          id="validationTooltipUsername"
                          aria-describedby="inputGroupPrepend"
                          defaultValue={1}
                          required
                        />
                      </CInputGroup>
                    </CCol>
                  </div>
                </div>
                <Button variant="primary" type="submit">
                      Add
                    </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Moal Create Costumer */}
      {/* fullscreen={true} */}
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Row>
          <Col xl={12} lg={12} md={12} xs={12}>
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Create custumer</h4>
                {/* Create input group */}
                <div className="input-group mb-3">
                  {/* Mail Label */}
                  <CCol md={4} className="position-relative ">
                    <CFormLabel htmlFor="validationTooltipUsername">
                      Mail
                    </CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText id="inputGroupPrepend">
                        <Icon className="fa fa-envelope" />
                      </CInputGroupText>
                      <CFormInput
                        type="text"
                        id="validationTooltipUsername"
                        defaultValue=""
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <CFormFeedback tooltip invalid>
                        Please choose a correct mail.
                      </CFormFeedback>
                    </CInputGroup>
                  </CCol>
                  {/* City label */}
                  <CCol>
                    <CFormLabel htmlFor="validationCustom04">City</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText id="inputGroupPrepend">
                        <Icon className="fas fa-city"></Icon>
                      </CInputGroupText>
                      <Select
                        classNamePrefix="selectproduct"
                        onChange={handleOnchangeCustom}
                        options={optionCountry}
                        isSearchable
                        className="h-100 w-80"
                        placeholder="--Select--"
                        required
                      />
                      <CFormFeedback invalid>
                        Please provide a valid city.
                      </CFormFeedback>
                    </CInputGroup>
                  </CCol>
                  {/* Group label */}
                  <CCol>
                    <CFormLabel htmlFor="validationCustom04">Group</CFormLabel>
                    <CInputGroup className="has-validation">
                      <CInputGroupText id="inputGroupPrepend">
                        <Icon className="fas fa-users"></Icon>
                      </CInputGroupText>
                      <Select
                        classNamePrefix="selectproduct"
                        onChange={handleOnchangeCustom}
                        options={optionGroups}
                        isSearchable
                        className="h-100"
                        placeholder="--Select--"
                      />
                      <CFormFeedback invalid>
                        Please provide a valid group.
                      </CFormFeedback>
                    </CInputGroup>
                  </CCol>
                </div>
                {/* Username  */}
                <Row>
                <CCol lg={6} md={12} xs={12}>
                  <CFormLabel htmlFor="validationTooltipUsername">
                    Username
                  </CFormLabel>
                  <CInputGroup className="has-validation">
                    <CInputGroupText id="inputGroupPrepend">
                      <Icon className="fa fa-user" />
                    </CInputGroupText>
                    <CFormInput
                      type="text"
                      id="validationTooltipUsername"
                      defaultValue=""
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <CFormFeedback tooltip invalid>
                      Please choose a correct Username.
                    </CFormFeedback>
                  </CInputGroup>
                </CCol>
                <CCol lg={4} md={12} xs={12}>
                            <CFormLabel htmlFor="validationTooltipUsername" >
                                Meet
                              </CFormLabel>
                              <CInputGroup className="has-validation">
                                <CInputGroupText id="inputGroupPrepend">
                                  <Icon className="fa fa-user" />
                                </CInputGroupText>
                                <CFormInput
                                  type="text"
                                  id="validationTooltipUsername"
                                  defaultValue=""
                                  aria-describedby="inputGroupPrepend"
                                  required
                                />
                                <CFormFeedback tooltip invalid>
                                  Please choose a meet link
                                </CFormFeedback>
                              </CInputGroup>

                            </CCol>
                            </Row>
              </div>
            </div>
          </Col>
          <Col
            sm={12}
            className="col-12 justify-content-center aling-items-center h-100 p-10"
          >
            <Card>
              <Card.Header>
                <h4 className="card-title">Activity List</h4>
              </Card.Header>
              <Card.Body className=" pt-0">
                <div className="table-responsive">
                  <>
                    <div className="d-flex">
                      <select
                        className=" mb-4 selectpage border me-1"
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        {[10, 25, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                      <GlobalFilter
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                      />
                    </div>
                    <table
                      {...getTableProps()}
                      className="table table-bordered text-nowrap mb-0"
                    >
                      <thead>
                        {headerGroups.map((headerGroup: any) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                className={column.className}
                              >
                                <span className="tabletitle">
                                  {column.render("Header")}
                                </span>
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa fa-angle-down"></i>
                                    ) : (
                                      <i className="fa fa-angle-up"></i>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row: any) => {
                          prepareRow(row);
                          return (
                            <tr className="text-center" {...row.getRowProps()}>
                              {row.cells.map((cell: any) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                      {/* Add new data */}
                      <tfoot>
                        <tr>
                          <td colSpan={6} className="text-center">
                            <Button
                              variant="primary"
                              className="btn btn-primary"
                              onClick={() => {
                                setShowActivity(true);
                              }}
                            >
                              Add New Activity
                            </Button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="d-block d-sm-flex mt-4 ">
                      <span className="">
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                      </span>
                      <span className="ms-sm-auto ">
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {" Previous "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            previousPage();
                          }}
                          disabled={!canPreviousPage}
                        >
                          {" << "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            previousPage();
                          }}
                          disabled={!canPreviousPage}
                        >
                          {" < "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            nextPage();
                          }}
                          disabled={!canNextPage}
                        >
                          {" > "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 my-1"
                          onClick={() => {
                            nextPage();
                          }}
                          disabled={!canNextPage}
                        >
                          {" >> "}
                        </Button>
                        <Button
                          variant=""
                          className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {" Next "}
                        </Button>
                      </span>
                    </div>
                  </>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* Col to right */}
          <Col
            sm={12}
            className="col-12 justify-content-right padding-bottom-20"
          >
            {/* div flex end */}
            <div className="h-100 d-flex align-items-end justify-content-end">
              <Button
                variant="primary"
                className="btn btn-info d-flex align-items-end justify-content-end "
                onClick={() => {
                  setShow(true);
                }}
              >
                Send Confirmation Mail
              </Button>
              <div className="px-4">
                <Button
                  variant="primary"
                  className="btn btn-primary   d-flex align-items-end justify-content-end  "
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Create Customer
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <br />
        </div>
      </Modal>
      {/*  END MODAL COSTUMERS */}

      {/* <!-- row --> */}
      <Row className="row-sm">
        <Col sm={12} lg={5} xl={4} xxl={3}>
          <Card className="custom-card">
            <div className="">
              <div className="main-content-contacts pt-0">
                <div className="main-content-left main-content-left-contacts slid1">
                  <Nav
                    defaultActiveKey="link-1"
                    className="nav main-nav-line  border-bottom-0 main-nav-line-chat  p-3"
                  >
                    <Nav.Item></Nav.Item>
                  </Nav>
                  <Scrollbars style={{ height: 750 }}>
                    <div className="main-contacts-list" id="mainContactList">
                      {renderCustomers(CUSTOMERSDATA)}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={12} lg={7} xl={8} xxl={9}>
          <div className="">
            <Link className="main-header-arrow" to="#" id="ChatBodyHide">
              <i className="icon ion-md-arrow-back"></i>
            </Link>
            <div className="main-content-body main-content-body-contacts card custom-card">
              <div className="main-contact-info-header pt-3">
                <div className="media">
                  <div className="main-img-user">
                    <img
                      alt="avatar"
                      src={require("../../assets/img/faces/6.jpg")}
                    />{" "}
                    <Link to="#">
                      <i className="fe fe-camera"></i>
                    </Link>
                  </div>
                  <div className="media-body">
                    <h5>{currentCostumer.nom}</h5>
                    <p>{currentCostumer.email}</p>
                  </div>
                </div>
                <div className="main-contact-action btn-list pt-3 pe-0 me-3">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit Customer</Tooltip>}
                  >
                    <Link
                      to="#"
                      className="btn ripple btn-primary text-white btn-icon"
                      data-placement="top"
                      data-bs-toggle="tooltip"
                      title="Edit Profile"
                    >
                      <i className="fe fe-edit"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete Customer</Tooltip>}
                  >
                    <Link
                      to="#"
                      className="btn ripple btn-secondary text-white btn-icon"
                      data-placement="top"
                      data-bs-toggle="tooltip"
                      title="Delete Profile"
                    >
                      <i className="fe fe-trash-2"></i>
                    </Link>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="main-contact-info-body p-4">
                {/* Taula activitats */}
                <div>
                  <Col
                    sm={12}
                    className="col-12 justify-content-center aling-items-center h-100 p-10"
                  >
                    <Card>
                      <Card.Header>
                        <h4 className="card-title">Activity List</h4>
                      </Card.Header>
                      <Card.Body className=" pt-0">
                        <div className="table-responsive">
                          <>
                            <div className="d-flex">
                              <select
                                className=" mb-4 selectpage border me-1"
                                value={pageSize}
                                onChange={(e) =>
                                  setPageSize(Number(e.target.value))
                                }
                              >
                                {[10, 25, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                  </option>
                                ))}
                              </select>
                              <GlobalFilter
                                filter={globalFilter}
                                setFilter={setGlobalFilter}
                              />
                            </div>
                            <table
                              {...getTableProps()}
                              className="table table-bordered text-nowrap mb-0"
                            >
                              <thead>
                                {headerGroups.map((headerGroup: any) => (
                                  <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column: any) => (
                                      <th
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                        className={column.className}
                                      >
                                        <span className="tabletitle">
                                          {column.render("Header")}
                                        </span>
                                        <span>
                                          {column.isSorted ? (
                                            column.isSortedDesc ? (
                                              <i className="fa fa-angle-down"></i>
                                            ) : (
                                              <i className="fa fa-angle-up"></i>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </th>
                                    ))}
                                  </tr>
                                ))}
                              </thead>
                              <tbody {...getTableBodyProps()}>
                                {page.map((row: any) => {
                                  prepareRow(row);
                                  return (
                                    <tr
                                      className="text-center"
                                      {...row.getRowProps()}
                                    >
                                      {row.cells.map((cell: any) => {
                                        return (
                                          <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                              {/* Add new data */}
                              <tfoot>
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    <Button
                                      variant="primary"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        setShowActivity(true);
                                      }}
                                    >
                                      Add New Activity
                                    </Button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            <div className="d-block d-sm-flex mt-4 ">
                              <span className="">
                                Page{" "}
                                <strong>
                                  {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                              </span>
                              <span className="ms-sm-auto ">
                                <Button
                                  variant=""
                                  className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
                                  onClick={() => gotoPage(0)}
                                  disabled={!canPreviousPage}
                                >
                                  {" Previous "}
                                </Button>
                                <Button
                                  variant=""
                                  className="btn-default tablebutton me-2 my-1"
                                  onClick={() => {
                                    previousPage();
                                  }}
                                  disabled={!canPreviousPage}
                                >
                                  {" << "}
                                </Button>
                                <Button
                                  variant=""
                                  className="btn-default tablebutton me-2 my-1"
                                  onClick={() => {
                                    previousPage();
                                  }}
                                  disabled={!canPreviousPage}
                                >
                                  {" < "}
                                </Button>
                                <Button
                                  variant=""
                                  className="btn-default tablebutton me-2 my-1"
                                  onClick={() => {
                                    nextPage();
                                  }}
                                  disabled={!canNextPage}
                                >
                                  {" > "}
                                </Button>
                                <Button
                                  variant=""
                                  className="btn-default tablebutton me-2 my-1"
                                  onClick={() => {
                                    nextPage();
                                  }}
                                  disabled={!canNextPage}
                                >
                                  {" >> "}
                                </Button>
                                <Button
                                  variant=""
                                  className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
                                  onClick={() => gotoPage(pageCount - 1)}
                                  disabled={!canNextPage}
                                >
                                  {" Next "}
                                </Button>
                              </span>
                            </div>
                          </>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
                {/* End taula activitats */}
                <div className="media-list pb-0">
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Group :</label>{" "}
                        <span className="tx-medium">
                          <h4>{currentCostumer.group}</h4>
                        </span>
                      </div>
                      <div>
                        <label>Group Role:</label>{" "}
                        <span className="tx-medium">
                          {currentCostumer.groupRol}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Phone :</label>{" "}
                        <span className="tx-medium">
                          {currentCostumer.telefon}
                        </span>
                      </div>
                      <div>
                        <label>City :</label>{" "}
                        <span className="tx-medium">
                          {currentCostumer.city}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* <!-- End Row --> */}
    </div>
  );
}
