import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Modal,
  Button,
  ProgressBar,
  Form,
  FormGroup,
  InputGroup,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Polyline,
  Rectangle,
  Polygon,
  Circle,
  Marker,
  useMapEvent,
  useMap,
} from "react-leaflet";
import Select from "react-select";

import { addCoordinateTransforms, fromLonLat } from "ol/proj";
import { icon, LatLng, latLng, map } from "leaflet";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "../../../index.scss";
import src from "react-select/dist/declarations/src";
import Scrollbars from "react-custom-scrollbars";
import { LIST_LATEST_NEWS } from "../../../Data/dataLatestNews";
import { Checkbox } from "@material-ui/core";
import { CLINETDATA } from "../../../Data/dataCostumers";
import { Wrapper } from "../../Helpers/RichText";

const UserDocuments = () => {
  const [Singleselect, setSingleselect] = useState<any>("");

  const [Fileuploader, setFileuploader] = React.useState([]);
  const [Assegurances, setAssegurances] = React.useState([]);
  const[Alergies, setAlergies] = React.useState([]);
  const [Vol, setVol] = React.useState([]);
  const [Passaport, setPassaport] = React.useState([]);

  var options: any = [];

  useEffect(() => {
    CLINETDATA.map((item) => {
      options.push({ value: item.idUser, label: item.nom });
    });
  }, []);

  function handleAcceptedFiles(
    files: any,
    Fileuploader: any,
    setFileuploader: any
  ) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFileuploader(files);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function renderDropzone(
    text: String,
    Fileuploader: any,
    setFileuploader: any
  ) {
    return (
      <>
        <label className="form-label">{text}</label>
        {Fileuploader.length == 0 && Fileuploader != null ? (
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles, Fileuploader, setFileuploader);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone dz-clickable w-75 h-75 d-flex justify-content-center">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <div className="justify-content-center mt-1 dropzoneicon ">
                    <i className="mdi mdi-apple-mobileme justify-content-center mt-4 mb-1"></i>
                  </div>
                  <p style={{ color: "#9393b5" }}>
                    Drop files here or click to upload.
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
        ) : (
          <div className="list-unstyled mb-0" id="file-previews">
            {Fileuploader.map((f: any, i: any) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      {/* Close delete button */}
                      <Col className="col-auto">
                        <button
                          type="button"
                          className="btn btn-link font-18 text-muted"
                          onClick={() => {
                            let newFileList = [...Fileuploader];
                            newFileList.splice(i, 1);
                            setFileuploader(newFileList);
                          }}
                        >
                          <i className="dripicons-cross">x</i>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <h4 className="content-title mb-2">Documents</h4>
        </div>
        <div className="justify-content-center mt-2"></div>
      </div>
      <Row>
        <Col lg={4} md={12}>
          <Card>
            <Card.Body>
              <div>
                <h6 className="card-title mb-1">Select Name</h6>
              </div>
              <div className="mb-4">
                <div className=" SlectBox">
                  <Select
                    defaultValue={Singleselect}
                    onChange={setSingleselect}
                    options={options}
                    placeholder="Name..."
                    classNamePrefix="selectform"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8} md={12}>
          <Card>
            <Card.Body>
              <div>
                <h6 className="card-title mb-1">Documents</h6>
              </div>

              <div className="mb-4">
                <Card>
                  <Card.Header className="border-bottom-0">
                    <h3 className="card-title">Insurance</h3>
                  </Card.Header>
                  {renderDropzone("", Assegurances, setAssegurances)}
                  <Card.Body>{Wrapper("Enter description ...")}</Card.Body>
                </Card>

                <Card>
                  <Card.Header className="border-bottom-0">
                    <h3 className="card-title">Allergies</h3>
                  </Card.Header>
                  {renderDropzone("", Alergies, setAlergies)}
                  <Card.Body>{Wrapper("Enter description ...")}</Card.Body>
                </Card>
                <Row>
                  <Col lg={6} md={12}>
                    <Card>
                      <Card.Header className="border-bottom-0">
                        <h3 className="card-title">Passport</h3>
                      </Card.Header>
                      {renderDropzone("", Passaport, setPassaport)}
                    </Card>
                  </Col>
                  <Col lg={6} md={12}>
                    <Card>
                      <Card.Header className="border-bottom-0">
                        <h3 className="card-title">Flight</h3>
                      </Card.Header>
                      {renderDropzone("", Vol, setVol)}
                    </Card>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

UserDocuments.propTypes = {};

UserDocuments.defaultProps = {};

export default UserDocuments;
