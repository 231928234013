import React, { useEffect } from "react";
import { Breadcrumb, Button, Card, Col, Row,Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Switcherdata from "../../Data/switcherData";
import Dropzone from "react-dropzone";
import IntlTelInput from "react-intl-tel-input";
import { Wrapper } from "../Helpers/RichText";
import { Icon } from "@material-ui/core";

export const AgencySettings = () => {
  useEffect(() => {
    Switcherdata.localStorageBackUp();
  });

  // File Uploader
  const [Fileuploader, setFileuploader] = React.useState([]);

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFileuploader(files);
  }
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  // end file uploader

  function changeBackgroundColor(userColor: any) {
    localStorage.setItem("primaryBackgroundColor", userColor);
  }

  function changePrimaryColor(userColor: any) {
    // var userColor = document.getElementById("colorID").value;
    localStorage.setItem("primaryColor", userColor);
    // to store value as opacity 0.95 we use 95
    localStorage.setItem("primaryHoverColor", userColor + 95);
    localStorage.setItem("primaryBorderColor", userColor);
    localStorage.setItem("primaryTransparent", userColor + 20);

    const dynamicPrimaryLight = document.querySelectorAll(
      "input.color-primary-light"
    );

    Switcherdata.dynamicLightPrimaryColor(dynamicPrimaryLight, userColor);

    // let myonoffswitch6: any = document.getElementById(
    //   "myonoffswitch1"
    // ) as HTMLInputElement;
    // myonoffswitch6.checked = true;

    // Adding
    document.querySelector("body")?.classList.add("light-theme");

    // Removing
    document.querySelector("body")?.classList.remove("dark-theme");
    document.querySelector("body")?.classList.remove("transparent-theme");

    localStorage.removeItem("darkPrimaryColor");
    localStorage.removeItem("transparentPrimaryColor");
    localStorage.removeItem("transparentBgColor");
    localStorage.removeItem("transparent-bgImgPrimaryColor");
    localStorage.removeItem("BgImage");

    Switcherdata.name();
  }

  function transparentPrimaryColor(userColor: any) {
    // var userColor = document.getElementById("transparentPrimaryColorID").value;

    localStorage.setItem("transparentPrimaryColor", userColor);

    const PrimaryTransparent = document.querySelectorAll(
      "input.color-primary-transparent"
    );

    Switcherdata.dynamicTransparentPrimaryColor(PrimaryTransparent, userColor);

    let myonoffswitchTransparent: any = document.getElementById(
      "myonoffswitchTransparent"
    ) as HTMLInputElement;
    myonoffswitchTransparent.checked = true;

    // Adding
    document.querySelector("body")?.classList.add("transparent-theme");

    // Removing
    document.querySelector("body")?.classList.remove("light-theme");
    document.querySelector("body")?.classList.remove("dark-theme");
    // document.querySelector("body")?.classList.remove("bg-img1");
    // document.querySelector("body")?.classList.remove("bg-img2");
    // document.querySelector("body")?.classList.remove("bg-img3");
    // document.querySelector("body")?.classList.remove("bg-img4");

    localStorage.removeItem("primaryColor");
    localStorage.removeItem("primaryHoverColor");
    localStorage.removeItem("primaryBorderColor");
    localStorage.removeItem("primaryTransparent");
    localStorage.removeItem("darkPrimaryColor");
    localStorage.removeItem("transparent-bgImgPrimaryColor");
    localStorage.removeItem("BgImage");

    Switcherdata.name();
  }
  function BgTransparentBackground(userColor: any) {
    // var userColor = document.getElementById("transparentBgColorID").value;

    localStorage.setItem("transparentBgColor", userColor);

    const dynamicBackgroundColor = document.querySelectorAll(
      "input.color-bg-transparent"
    );

    Switcherdata.dynamicBgTransparentBackground(
      dynamicBackgroundColor,
      userColor
    );

    let myonoffswitchTransparent: any = document.getElementById(
      "myonoffswitchTransparent"
    ) as HTMLInputElement;
    myonoffswitchTransparent.checked = true;

    // Adding
    document.querySelector("body")?.classList.add("transparent-theme");

    // Removing
    document.querySelector("body")?.classList.remove("light-theme");
    document.querySelector("body")?.classList.remove("dark-theme");
    // document.querySelector("body")?.classList.remove("bg-img1");
    // document.querySelector("body")?.classList.remove("bg-img2");
    // document.querySelector("body")?.classList.remove("bg-img3");
    // document.querySelector("body")?.classList.remove("bg-img4");
    document.querySelector("body")?.classList.remove("light-header");
    document.querySelector("body")?.classList.remove("color-header");
    document.querySelector("body")?.classList.remove("dark-header");
    document.querySelector("body")?.classList.remove("gradient-header");
    document.querySelector("body")?.classList.remove("light-menu");
    document.querySelector("body")?.classList.remove("color-menu");
    document.querySelector("body")?.classList.remove("dark-menu");
    document.querySelector("body")?.classList.remove("gradient-menu");

    localStorage.removeItem("primaryColor");
    localStorage.removeItem("primaryHoverColor");
    localStorage.removeItem("primaryBorderColor");
    localStorage.removeItem("primaryTransparent");
    localStorage.removeItem("darkPrimaryColor");
    localStorage.removeItem("transparent-bgImgPrimaryColor");
    localStorage.removeItem("BgImage");

    Switcherdata.name();
  }
  function BgImgTransparentPrimaryColor(userColor: any) {
    // var userColor = document.getElementById(
    //   "transparentBgImgPrimaryColorID"
    // ).value;

    localStorage.setItem("nowatransparent-bgImgPrimaryColor", userColor);

    const dynamicPrimaryImgTransparent = document.querySelectorAll(
      "input.color-primary-transparent"
    );

    Switcherdata.dynamicBgImgTransparentPrimaryColor(
      dynamicPrimaryImgTransparent,
      userColor
    );
    // console.log(dynamicPrimaryImgTransparent);
    let myonoffswitchTransparent: any = document.getElementById(
      "myonoffswitchTransparent"
    ) as HTMLInputElement;
    myonoffswitchTransparent.checked = true;

    // Adding
    document.querySelector("body")?.classList.add("transparent-theme");

    // Removing
    document.querySelector("body")?.classList.remove("light-theme");
    document.querySelector("body")?.classList.remove("dark-theme");
    document.querySelector("body")?.classList.remove("light-header");
    document.querySelector("body")?.classList.remove("color-header");
    document.querySelector("body")?.classList.remove("dark-header");
    document.querySelector("body")?.classList.remove("gradient-header");
    document.querySelector("body")?.classList.remove("light-menu");
    document.querySelector("body")?.classList.remove("color-menu");
    document.querySelector("body")?.classList.remove("dark-menu");
    document.querySelector("body")?.classList.remove("gradient-menu");
    localStorage.removeItem("nowaPrimaryColor");
    localStorage.removeItem("nowaprimaryHoverColor");
    localStorage.removeItem("nowaprimaryBorderColor");
    localStorage.removeItem("nowaprimaryTransparent");
    localStorage.removeItem("nowadarkPrimaryColor");
    localStorage.removeItem("nowatransparentPrimaryColor");
    localStorage.removeItem("nowatransparentBgColor");

    let html: any = document.querySelector("html");
    html.style.removeProperty("--transparent-body");

    // if (
    //   document.querySelector("body")?.classList.contains("bg-img1") ===
    //     false &&
    //   document.querySelector("body")?.classList.contains("bg-img2") ===
    //     false &&
    //   document.querySelector("body")?.classList.contains("bg-img3") ===
    //     false &&
    //   document.querySelector("body")?.classList.contains("bg-img4") === false
    // ) {
    //   document.querySelector("body")?.classList.add("bg-img1");
    //   localStorage.setItem("nowaBgImage", "bg-img1");
    // }
    Switcherdata.name();
  }
  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Agency
          </span>
        </div>
        <div className="justify-content-center mt-2">
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- row --> */}
      <div className="container p-0 p-sm-5">
        <Row>
          <Col sm={12} md={12} lg={6}>
            {/* <!-- Switcher --> */}
            <Col lg={8}>
              <span className="main-content-title mg-b-0 mg-b-lg-1">Menu</span>
              <div className="switch-toggle d-flex">
                <span className="me-auto">Vertical Menu</span>
                <p className="onoffswitch2 my-0">
                  <input
                    type="radio"
                    name="onoffswitch15"
                    id="myonoffswitch34"
                    onClick={() => Switcherdata.VerticalMenu()}
                    className="onoffswitch2-checkbox"
                    defaultChecked
                  />
                  <label
                    htmlFor="myonoffswitch34"
                    className="onoffswitch2-label"
                  ></label>
                </p>
              </div>
              <div className="switch-toggle d-flex mt-2">
                <span className="me-auto">Horizantal Menu</span>
                <p className="onoffswitch2 my-0">
                  <input
                    type="radio"
                    name="onoffswitch15"
                    id="myonoffswitch111"
                    onClick={() => Switcherdata.HorizontalHoverMenu()}
                    className="onoffswitch2-checkbox"
                  />
                  <label
                    htmlFor="myonoffswitch111"
                    className="onoffswitch2-label"
                  ></label>
                </p>
              </div>
            </Col>
          </Col>
          <Col lg={6}>
            <div className="swichermainleft mb-3">
              <span className="main-content-title mg-b-0 mg-b-lg-1">
                {" "}
                Colors{" "}
              </span>
              <div className="skin-body">
                <div className="switch_section">
                  <div className="switch-toggle d-flex">
                    <span className="me-auto">Font primary color</span>
                    <div className="">
                      <input
                        className="wd-25 ht-25 input-color-picker color-primary-light"
                        defaultValue="#38cab3"
                        id="colorID"
                        onChange={(e) => changePrimaryColor(e.target.value)}
                        type="color"
                        data-id="bg-color"
                        data-id1="bg-hover"
                        data-id2="bg-border"
                        data-id7="transparentcolor"
                        name="lightPrimary"
                      />
                    </div>
                  </div>
                  <div className="switch-toggle d-flex mt-2">
                    <span className="me-auto">Background color</span>
                    <div className="">
                      <input
                        className="wd-25 ht-25 input-color-picker color-primary-light"
                        defaultValue="#38cab3"
                        id="colorID"
                        onChange={(e) => changeBackgroundColor(e.target.value)}
                        type="color"
                        data-id="bg-color"
                        data-id1="bg-hover"
                        data-id2="bg-border"
                        data-id7="transparentcolor"
                        name="lightPrimary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <label className="form-label">Logo icon</label>
            {Fileuploader.length == 0 && Fileuploader != null ? (
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone dz-clickable w-100 h-75 d-flex justify-content-center">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <div className="justify-content-center mt-1 dropzoneicon ">
                        <i className="mdi mdi-apple-mobileme justify-content-center mt-4 mb-1"></i>
                      </div>
                      <p style={{ color: "#9393b5" }}>
                        Drop files here or click to upload.
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div className="list-unstyled mb-0" id="file-previews">
                {Fileuploader.map((f: any, i: any) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                          {/* Close delete button */}
                          <Col className="col-auto">
                            <button
                              type="button"
                              className="btn btn-link font-18 text-muted"
                              onClick={() => {
                                let newFileList = [...Fileuploader];
                                newFileList.splice(i, 1);
                                setFileuploader(newFileList);
                              }}
                            >
                              <i className="dripicons-cross">x</i>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            )}
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div className="form-group">
              <label className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Agency title"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Mail</label>
              <input type="text" className="form-control" placeholder="Mail" />
            </div>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <div className="form-group">
                  <label className="form-label">Web</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Website"
                  />
                </div>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <label className="form-label">Phone</label>
                <IntlTelInput
                  containerClassName="intl-tel-input"
                  inputClassName="form-control"
                  fieldId="phone"
                  preferredCountries={["es", "fr", "gb", "us", "de", "it"]}
                />
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Card>
                  <Card.Header className="border-bottom-0">
                    <h3 className="card-title">Destination tips</h3>
                  </Card.Header>
                  <Card.Body>
                    {Wrapper("Enter your tips here ...")}
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Card>
                  <Card.Header className="border-bottom-0">
                    <h3 className="card-title">Travel Tips</h3>
                  </Card.Header>
                  <Card.Body>
                    {Wrapper("Enter your tips here ...")}
                  </Card.Body>
                </Card>
              </Col>
              
              <Col sm={12} md={12} lg={6} className="justify-content-center mb-2">
            <label className="form-label">Video</label>
            {Fileuploader.length == 0 && Fileuploader != null ? (
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone dz-clickable w-100 h-75 d-flex justify-content-center">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <div className="justify-content-center mt-1 dropzoneicon ">
                        <i className="mdi mdi-apple-mobileme justify-content-center mt-4 mb-1"></i>
                      </div>
                      <p style={{ color: "#9393b5" }}>
                        Drop files here or click to upload.
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div className="list-unstyled mb-0" id="file-previews">
                {Fileuploader.map((f: any, i: any) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                          {/* Close delete button */}
                          <Col className="col-auto">
                            <button
                              type="button"
                              className="btn btn-link font-18 text-muted"
                              onClick={() => {
                                let newFileList = [...Fileuploader];
                                newFileList.splice(i, 1);
                                setFileuploader(newFileList);
                              }}
                            >
                              <i className="dripicons-cross">x</i>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            )}
          </Col>
          <Col sm={12} md={12} lg={12}>
                <Card>
                  <Card.Header className="border-bottom-0">
                    <h3 className="card-title">Video Description</h3>
                  </Card.Header>
                  <Card.Body>
                    {Wrapper("Enter your tips here ...")}
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="accordiondclose">
      <Col lg={12} md={12}>
        <Card>
          <Card.Body>
            <div>
              <h6 className="card-title mb-1">FAQS</h6>
              <p className="text-muted card-sub-title">
              </p>
            </div>
            <Accordion defaultActiveKey="Item1" id="accordion">
              <Accordion.Item eventKey="Item1" className="">
                <Accordion.Header
                  className=" "
                  id="headingOne"
                >
                 What is the voltage used in Anoia?
                </Accordion.Header>
                <Accordion.Body className="borders">
                  Depends on the area
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="Item2" className="">
                <Accordion.Header
                  className=" "
                  id="headingTwo"
                >
                  What is the tipping system in Spain?
                </Accordion.Header>
                <Accordion.Body className="borders">
                 Depends on the area
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="Item3" className="">
                <Accordion.Header
                  className=" "
                  id="headingThree"
                >
                 Am I going to have internet connectivity?
                </Accordion.Header>
                <Accordion.Body className="borders">
                  Depends on the area
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="ItemAdd" className="">
                <Accordion.Header
                  className=" justify-content-between justify-content-center"
                  id="headingThree"
                >
                 + Add FAQ
                </Accordion.Header>
                <Accordion.Body className="borders">
                  <div className="form-group">
                    <label className="form-label">Question</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Question"
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Answer</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Answer"
                    />
                  </div>
                  <Button variant="primary"
                  onClick={() => { 
                    console.log("Add FAQ");
                  }}
                  >Add</Button>

                </Accordion.Body>
              </Accordion.Item>

            </Accordion>

          </Card.Body>
        </Card>
      </Col>
    </Row>
          </Col>
        </Row>
      </div>
      {/* <!-- row closed --> */}
    </div>
  );
};

AgencySettings.propTypes = {};

AgencySettings.defaultProps = {};

export default AgencySettings;
