import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  Breadcrumb,
  Col,
  Row,
  Card,
  Button,
  ProgressBar,
  Form,
  FormGroup,
  Modal,
  InputGroup,
} from "react-bootstrap";
import {
  CForm,
  CCol,
  CFormLabel,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
} from "@coreui/react";
import Select from 'react-select';


import { COLUMNS, DATATABLE, GlobalFilter } from "../../Data/dataCostumers";
import { optionCountry, optionGroups } from "../../Data/dataCostumers";
import { Icon } from "@material-ui/core";
export default function CreateCustomer() {
  const tableInstance = useTable(
    {
      columns: COLUMNS,
      data: DATATABLE,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);

  //Get custom data from select country
  const [Custom, setCustom] = useState("");
  const handleOnchangeCustom = () => {
    setCustom(Custom);
  };

  const {
    getTableProps, // table props from react-table
    headerGroups, // headerGroups, if your table has groupings
    getTableBodyProps, // table body props from react-table
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    setGlobalFilter,
    page, // use, page or rows
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  }: any = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Customers</span>
        </div>
      </div>
      {/* Modal Add activity */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modal-header">
          <h6 className="modal-title">Add Activity</h6>
          <Button
            variant=""
            className="btn-close"
            type="button"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </Button>
        </Modal.Header>

        <Modal.Body className="modal-body">
          {" "}
          <div className="p-4">
            <Form className="form-horizontal">
              <FormGroup className="form-group">
                <Form.Control
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Name"
                />
              </FormGroup>
              <FormGroup className="form-group">
                <Form.Control
                  type="text"
                  className="form-control"
                  id="inputName1"
                  placeholder="Role"
                />
              </FormGroup>
              <FormGroup className="form-group">
                <Form.Control
                  type="email"
                  className="form-control"
                  id="inputEmail3"
                  placeholder="Email"
                />
              </FormGroup>
              <FormGroup className="form-group mb-0 justify-content-end">
                <div className="checkbox">
                  <div className="custom-checkbox custom-control">
                    <input
                      type="checkbox"
                      data-checkboxes="mygroup"
                      className="custom-control-input"
                      id="checkbox-2"
                    />
                    <label
                      htmlFor="checkbox-2"
                      className="custom-control-label mt-1 text-dark"
                    >
                      New User?
                    </label>
                  </div>
                </div>
              </FormGroup>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="btn ripple btn-primary" type="button">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- row --> */}
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
          <Row>
            <Col xl={12} lg={12} md={12} xs={12}>
              <Card>
                <Card.Body>
                  {/* Taula client activitat  */}
                  {/* <!-- row  --> */}
                  <Row>
                    <Col xl={12} lg={12} md={12} xs={12}>
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Create custumer</h4>
                          {/* Create input group */}
                          <div className="input-group mb-3">
                            {/* Mail Label */}
                            <CCol md={4} className="position-relative ">
                              <CFormLabel htmlFor="validationTooltipUsername">
                                Mail
                              </CFormLabel>
                              <CInputGroup className="has-validation">
                                <CInputGroupText id="inputGroupPrepend">
                                  <Icon className="fa fa-envelope" />
                                </CInputGroupText>
                                <CFormInput
                                  type="text"
                                  id="validationTooltipUsername"
                                  defaultValue=""
                                  aria-describedby="inputGroupPrepend"
                                  required
                                />
                                <CFormFeedback tooltip invalid>
                                  Please choose a correct mail.
                                </CFormFeedback>
                              </CInputGroup>
                            </CCol>
                            {/* City label */}
                            <CCol md={3} >
                              <CFormLabel htmlFor="validationCustom04">
                                City
                              </CFormLabel>
                              <CInputGroup className="has-validation">
                                <CInputGroupText id="inputGroupPrepend">
                                  <Icon className="fas fa-city"></Icon>
                                </CInputGroupText>
                              <Select 
                                classNamePrefix="selectproduct"
                                onChange={handleOnchangeCustom}
                                options={optionCountry}
                                isSearchable
                                className="h-100 w-80"
                                placeholder="--Select--"
                                required
                              />
                              <CFormFeedback invalid>
                                Please provide a valid city.
                              </CFormFeedback>
                              </CInputGroup>
                            </CCol>
                            {/* Group label */}
                            <CCol md={3}  className="h-100">
                              <CFormLabel htmlFor="validationCustom04">
                                Group
                              </CFormLabel>
                              <CInputGroup className="has-validation">
                                <CInputGroupText id="inputGroupPrepend">
                                  <Icon className="fas fa-users"></Icon>
                                </CInputGroupText>
                              <Select 
                                classNamePrefix="selectproduct"
                                onChange={handleOnchangeCustom}
                                options={optionGroups}
                                isSearchable
                                className="h-100"
                                placeholder="--Select--"
                              />
                              <CFormFeedback invalid>
                                Please provide a valid group.
                              </CFormFeedback>
                              </CInputGroup>
                            </CCol>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col
                      sm={10}
                      className="col-12 justify-content-center aling-items-center h-100"
                    >
                      <Card>
                        <Card.Header>
                          <h4 className="card-title">Activity List</h4>
                        </Card.Header>
                        <Card.Body className=" pt-0">
                          <div className="table-responsive">
                            <>
                              <div className="d-flex">
                                <select
                                  className=" mb-4 selectpage border me-1"
                                  value={pageSize}
                                  onChange={(e) =>
                                    setPageSize(Number(e.target.value))
                                  }
                                >
                                  {[10, 25, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                      Show {pageSize}
                                    </option>
                                  ))}
                                </select>
                                <GlobalFilter
                                  filter={globalFilter}
                                  setFilter={setGlobalFilter}
                                />
                              </div>
                              <table
                                {...getTableProps()}
                                className="table table-bordered text-nowrap mb-0"
                              >
                                <thead>
                                  {headerGroups.map((headerGroup: any) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                      {headerGroup.headers.map(
                                        (column: any) => (
                                          <th
                                            {...column.getHeaderProps(
                                              column.getSortByToggleProps()
                                            )}
                                            className={column.className}
                                          >
                                            <span className="tabletitle">
                                              {column.render("Header")}
                                            </span>
                                            <span>
                                              {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                  <i className="fa fa-angle-down"></i>
                                                ) : (
                                                  <i className="fa fa-angle-up"></i>
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                  {page.map((row: any) => {
                                    prepareRow(row);
                                    return (
                                      <tr
                                        className="text-center"
                                        {...row.getRowProps()}
                                      >
                                        {row.cells.map((cell: any) => {
                                          return (
                                            <td {...cell.getCellProps()}>
                                              {cell.render("Cell")}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                                {/* Add new data */}
                                <tfoot>
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      <Button
                                        variant="primary"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          setShow(true);
                                        }}
                                      >
                                        Add New Activity
                                      </Button>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <div className="d-block d-sm-flex mt-4 ">
                                <span className="">
                                  Page{" "}
                                  <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                  </strong>{" "}
                                </span>
                                <span className="ms-sm-auto ">
                                  <Button
                                    variant=""
                                    className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                  >
                                    {" Previous "}
                                  </Button>
                                  <Button
                                    variant=""
                                    className="btn-default tablebutton me-2 my-1"
                                    onClick={() => {
                                      previousPage();
                                    }}
                                    disabled={!canPreviousPage}
                                  >
                                    {" << "}
                                  </Button>
                                  <Button
                                    variant=""
                                    className="btn-default tablebutton me-2 my-1"
                                    onClick={() => {
                                      previousPage();
                                    }}
                                    disabled={!canPreviousPage}
                                  >
                                    {" < "}
                                  </Button>
                                  <Button
                                    variant=""
                                    className="btn-default tablebutton me-2 my-1"
                                    onClick={() => {
                                      nextPage();
                                    }}
                                    disabled={!canNextPage}
                                  >
                                    {" > "}
                                  </Button>
                                  <Button
                                    variant=""
                                    className="btn-default tablebutton me-2 my-1"
                                    onClick={() => {
                                      nextPage();
                                    }}
                                    disabled={!canNextPage}
                                  >
                                    {" >> "}
                                  </Button>
                                  <Button
                                    variant=""
                                    className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                  >
                                    {" Next "}
                                  </Button>
                                </span>
                              </div>
                            </>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    {/* Col to right */}
                    <Col sm={12} className="col-12 justify-content-right ">
                      {/* div flex end */}
                      <div className="h-100 d-flex align-items-end justify-content-end">
                        <Button
                          variant="primary"
                          className="btn btn-info d-flex align-items-end justify-content-end"
                          onClick={() => {
                            setShow(true);
                          }}
                        >
                          Send Confirmation Mail
                        </Button>
                        <div className="px-4">
                          <Button
                            variant="primary"
                            className="btn btn-primary   d-flex align-items-end justify-content-end "
                            onClick={() => {
                              setShow(true);
                            }}
                          >
                            Create Customer
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* End Taula activitats */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}
