import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Modal,
  Button,
} from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Polyline,
  Rectangle,
  Polygon,
  Circle,
  Marker,
  useMapEvent,
  useMap,
} from "react-leaflet";
import Select from "react-select";

import { addCoordinateTransforms, fromLonLat } from "ol/proj";
import { icon, LatLng, latLng, map } from "leaflet";
import SearchBox from "../Maps/LeafletMaps/SearchBox";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "../../index.scss";
import src from "react-select/dist/declarations/src";
import Scrollbars from "react-custom-scrollbars";
import { API_URL } from "../../assets/Constants/constants";
import { AlertDismissibleExample } from "./MessagesModal";
// import { ACTIVITY_LIST } from "../../../Data/dataActivity";

export const ActivityModal = (show: any, setShow: any) => {

  const [mapPosition, setMapPosition] = React.useState(
      latLng([42.12033,2.452541])
  );
  const [markerPosition, setMarkerPosition] = React.useState(
    latLng([42.12033,2.452541])
  );
  const [Fileuploader, setFileuploader] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [searchText, setSearchText] = useState("");

  const [ form , setForm ] = useState({ 
    nom : "",
    descripcio : "",
    latitud : markerPosition.lat,
    longitud : markerPosition.lng,
    adreca: "",
    idTipus: React.useState<any>(""),
    web: "",
    imatge : "",
    preu : "",
    mostrarMapa : false,
    telefon : "",
    dialCode: "",
    codi : 1 ,
    codeId : Math.random().toString(36).substr(1, 10),
  });

  const options = [
    { value: "1", label: "Restaurant" },
    { value: "2", label: "Culture" },
    { value: "3", label: "Festival" },
    { value: "4", label: "Hotel" },
    { value: "5", label: "Info" },
    { value: "6", label: "Monument" },
    { value: "7", label: "Parking" },
    { value: "8", label: "Others" },
  ];


  const RecenterAutomatically = ({ lat, lng }: any) => {
    const map = useMap();
    useEffect(() => {
      if (
        markerPosition.lat == mapPosition.lat &&
        markerPosition.lng == mapPosition.lng 
        // && lat != undefined && lng != undefined
      ) {
       map.setView([lat, lng], map.getZoom());
      }
    }, [lat, lng]);
    return null;
  };

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        setMarkerPosition(e.target.getLatLng());
      },
    }),
    []
  );

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFileuploader(files);
  }
  
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  
 function riseError(error: any) {
    alert(error);
  }
  
  function sendData() {
    setForm({
      ...form,
      latitud: markerPosition.lat,
      longitud: markerPosition.lng,
      adreca: searchText,
      imatge : formatBytes(Fileuploader[0]),
    });
    console.log(form);
   fetch(API_URL + "/activitat/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Bearer": "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(form),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        if(data.code == 1)
        {
          alert("Activitat creada correctament");
          setShow(false);
        }else
        {
         setOpenModal(true);
        }
      }
      )
      .catch((error) => {
        console.error("Error:", error);
        AlertDismissibleExample(openModal,setOpenModal,'Error', 'Error al crear l\'activitat')
      }
      );
    }


    return(
      
        <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header
          closeButton
          style={{
            width: "70vw",
            maxHeight: "90vh",
            alignSelf: "center",
            backgroundColor: "white",
          }}
        >
                    {openModal ? (  AlertDismissibleExample(openModal,setOpenModal,'Error', 'Error al crear l\'activitat')) : null}

          <Modal.Title>Add new activity</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            width: "70vw",
            maxHeight: "100vh",
            alignSelf: "center",
            backgroundColor: "white",
          }}
        >
          <Row>
            <Col md={6}>
              <Row>
                <Col lg={6}>
                  <div>{SearchBox(setMapPosition, setMarkerPosition,searchText,setSearchText)}</div>
                  <div className="main-content-label mg-6 ">Basic</div>
                </Col>
                <Col lg={3}>
                  <input
                    type="text"
                    className="form-control"
                    value={markerPosition.lat}
                    // onChange={(e) => setMarkerPosition({lat: Number(e.target.value)  , lng: markerPosition.lng})}
                    placeholder="Latitud"
                  />
                </Col>
                <Col lg={3}>
                  <input
                    type="text"
                    className="form-control"
                    value={markerPosition.lng}
                    // onChange={(e) => setMarkerPosition({lat: markerPosition.lat, lng: e.target.value})}
                    placeholder="Longitud"
                  />
                </Col>
                <Col lg={12}>
                  <MapContainer
                    className="mapleaflet ht-200"
                    id="mapa"
                    style={{ height: "60vh", width: "100%" }}
                    center={mapPosition}
                    zoom={15}
                  >
                    <RecenterAutomatically
                      lat={mapPosition.lat}
                      lng={mapPosition.lng}
                    />

                    {/* when markerposition change move map to this position */}
                    <Marker
                      position={mapPosition}
                      draggable={true}
                      eventHandlers={eventHandlers}
                      icon={icon({
                        iconUrl: require("../../assets/img/point.png"),
                        iconSize: [40, 40],
                      })}
                    />
                    <TileLayer url="https://geoserveis.icgc.cat/styles/icgc_mapa_estandard/{z}/{x}/{y}.png" />
                  </MapContainer>
                </Col>
              </Row>
            </Col>
            {/* Segona columna */}

            <Col md={3}>
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <label className="form-label">Icon</label>
                    {Fileuploader.length == 0 && Fileuploader != null ? (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone dz-clickable w-100 h-25 d-flex justify-content-center">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <div className="justify-content-center mt-1 dropzoneicon ">
                                <i className="mdi mdi-apple-mobileme justify-content-center mt-4 mb-1"></i>
                              </div>
                              <p style={{ color: "#9393b5" }}>
                                Drop files here or click to upload.
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div className="list-unstyled mb-0" id="file-previews">
                        {Fileuploader.map((f: any, i: any) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  {/* Close delete button */}
                                  <Col className="col-auto">
                                    <button
                                      type="button"
                                      className="btn btn-link font-18 text-muted"
                                      onClick={() => {
                                        let newFileList = [...Fileuploader];
                                        newFileList.splice(i, 1);
                                        setFileuploader(newFileList);
                                      }}
                                    >
                                      <i className="dripicons-cross">x</i>
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    )}

                    <div className="form-group">
                      <label className="form-label">Activity</label>
                      <input
                        type="text"
                        value={form.nom}
                        onChange={(e) => {
                          setForm({ ...form, nom: e.target.value });
                        }}

                        className="form-control"
                        placeholder="Activity title"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control mg-t-20"
                        placeholder="Description of the activity"
                        required
                        rows={3}
                        value={form.descripcio}
                        onChange={(e) => {
                          setForm({ ...form, descripcio: e.target.value });
                        }}

                        defaultValue=""
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <Row>
                        <Col md={12}>
                          {/* IntlTelInput placeholder spain */}
                          <label className="form-label">Phone</label>
                          <IntlTelInput
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            value={form.telefon}
                            onPhoneNumberChange={(status, value, countryData) => {
                              setForm({ ...form, telefon: value , dialCode : "+"+ countryData.dialCode });
                            }}

                            fieldId="phone"
                            preferredCountries={[
                              "es",
                              "fr",
                              "gb",
                              "us",
                              "de",
                              "it",
                            ]}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
            <Col md={3}>
              <div>
                <p className="mg-b-10">Activity type</p>
                <div className=" SlectBox">
                  <Select
                    defaultValue={form.idTipus}
                    onChange={(e) => { setForm({ ...form, idTipus: e.value }); }}
                    options={options}
                    placeholder="Select an option"
                    classNamePrefix="selectform"
                    isSearchable
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Activity price"
                    value={form.preu}
                    onChange={(e) => {
                      setForm({ ...form, preu: e.target.value });
                    }}

                  />
                </div>
              </div>
              <label className="form-label">Web</label>
              <input type="text" className="form-control" placeholder="Web" value={form.web} 
              onChange = {(e) => {setForm({...form, web: e.target.value});}}
               />
                    {/* Flex direction row */}
              <div className="form-group d-flex pt-3 ">
                <label className="form-label">Show in Map </label>
                <div className="custom-switches-stacked mt-2">
                  <label className="custom-switch">
                    <input
                      type="checkbox"
                      name="custom-switch-checkbox"
                      className="custom-switch-input"
                      checked={form.mostrarMapa}
                      onChange={(e) => {
                        setForm({ ...form, mostrarMapa: e.target.checked });
                      }}

                    />
                    <span className="custom-switch-indicator"></span>
                    
                  </label>
                </div>
              </div>

            </Col>
            <Col
              sm={12}
              className="col-12 justify-content-right padding-bottom-20"
            >
              {/* div flex end */}
              <div className="h-100 d-flex align-items-end justify-content-end">
                <div className="px-4">
                  <Button
                    variant="primary"
                    className="btn btn-primary   d-flex align-items-end justify-content-end  "
                    onClick={() => {
                      sendData()                      
                    }}
                  >
                    Create Activity
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
    // return( <>{"Hoal"}</>)
}