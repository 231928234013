 import React from "react";
 import ReactApexChart from "react-apexcharts";

 export class Statistics1 extends React.Component<{}, { options: any, series: any}> {
   constructor(props:any) {
     super(props);

     this.state = {

       series: [
         {
           name: "Total Orders",
           data: [44, 42, 57, 86, 58, 55, 70, 43, 23, 54, 77, 34],
         },
         {
           name: "Total Sales",
           data: [34, 22, 37, 56, 21, 35, 60, 34, 56, 78, 89, 53],
         },
       ],
       options: {
         chart: {
           type: "bar",
           height: 280,
         },
         grid: {
           borderColor: "#f2f6f7",
           show: true,
         },
         colors: ['var(--primary-bg-color)' || "#38cab3", "#e4e7ed"],
         plotOptions: {
      
           bar: {
             borderradius: "5px",
             colors: {
               ranges: [
                 {
                   from: -100,
                   to: -46,
                   color: "#ebeff5",
                 },
                 {
                   from: -45,
                   to: 0,
                   color: "#ebeff5",
                 },
               ],
             },
             columnWidth: "40%",
           },
         },
         dataLabels: {
           enabled: false,
         },
         stroke: {
           show: true,
           width: 4,
           colors: ["transparent"],
         },
         legend: {
           show: true,
           position: "top",
         },
         xaxis: {
           type: "month",
           categories: [
             "Jan",
             "Feb",
             "Mar",
             "Apr",
             "May",
             "Jun",
             "Jul",
             "Aug",
             "sep",
             "oct",
             "nov",
             "dec",
           ],
           axisBorder: {
             show: true,
             color: "rgba(119, 119, 142, 0.05)",
             offsetX: 0,
             offsetY: 0,
           },
           axisTicks: {
             show: true,
             borderType: "solid",
             color: "rgba(119, 119, 142, 0.05)",
             width: 6,
             offsetX: 0,
             offsetY: 0,
           },
           labels: {
             rotate: -90,
           },
         },
         yaxis: {
           title: {
             text: "Growth",
             style: {
               color: "#adb5be",
               fontSize: "14px",
               fontFamily: "poppins, sans-serif",
               fontWeight: 600,
               cssClass: "apexcharts-yaxis-label",
             },
           },
           labels: {
             formatter: function (y:any) {
               return y.toFixed(0) + "";
             },
           },
         },
         fill: {
           opacity: 1
         },

       },


     };
   }



   render() {
     return (
       <div id="chart">
         <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={280} />
       </div>
     )
   }
 }
 export class Viewers extends React.Component<{}, { options: any, series: any, }>{
   constructor(props:any) {
     super(props);

     this.state = {

       series: [
         {
           name: "Male",
           data: [51, 44, 55, 42, 58, 50, 62],
         },
         {
           name: "Female",
           data: [56, 58, 38, 50, 64, 45, 55],
         },
       ],
       options: {
         chart: {
           height: 315,
           type: "line",
           toolbar: {
             show: false,
           },
           background: "none",
           fill: "#fff",
         },
         grid: {
           borderColor: "#f2f6f7",
         },
         colors: ['var(--primary-bg-color)' || "#38cab3", "#e4e7ed"],
         background: "transparent",
         dataLabels: {
           enabled: false,
         },
         stroke: {
           curve: "smooth",
           width: 2,
         },

         legend: {
           show: true,
           position: "top",
         },
         xaxis: {
           show: false,
           axisBorder: {
             show: false,
             color: "rgba(119, 119, 142, 0.05)",
             offsetX: 0,
             offsetY: 0,
           },
           axisTicks: {
             show: false,
             borderType: "solid",
             color: "rgba(119, 119, 142, 0.05)",
             width: 6,
             offsetX: 0,
             offsetY: 0,
           },
           labels: {
             rotate: -90,
           },
         },
         yaxis: {
           show: false,
           axisBorder: {
             show: false,
           },
           axisTicks: {
             show: false,
           },
         },
         tooltip: {
           x: {
             format: "dd/MM/yy HH:mm",
           },
         },
       },

     };
   }
   render() {
     return (
       <div id="chart">
         <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={300} />
       </div>
     )
   }
 }
 export const Radialbar:any = {
   className: "forth circle",
   series: [85],
   fill: {
     type: "gradient",
     gradient: {
       shade: "dark",
       type: "vertical",
       gradientToColors: ["#87D4F9"],
       stops: [0, 100],
     },
   },
   options: {
     colors: ['var(--primary-bg-color)'],

     stroke: {
       lineCap: "round",
     },
     plotOptions: {
       radialBar: {
         hollow: {},
       },
     },
   },
 };


 export const ACTIVITY_LIST:any = [
    {
      id: 1,
      titol: "Hostal Abat Cisneros",
      latitud: 41.3851,
      longitud: 2.1734,
      descripcio: "Es un hostal de 3 estrellas situado en el centro de Barcelona, a 5 minutos a pie de la plaza Cataluña y de la estación de metro de Urquinaona. Ofrece habitaciones con aire acondicionado y conexión Wi-Fi gratuita.",
      telefon: "938 77 77 01",
      preu: 25,
      icona: "https://www.hostalabatcisneros.com/wp-content/uploads/2019/03/logo-1.png",
      tipusActivitat: "Hotel",
      web: "https://www.hostalabatcisneros.com/",
      adreca: "Monestir de Montserrat, s/n, 08199 Barcelona",
    },
    {
      id: 2,
      titol: "Els Cubs",
      latitud: 41.3851,
      longitud: 1.3734,
      descripcio: "El Hostal Els Cubs se encuentra en el centro de Barcelona, ​​a 5 minutos a pie de la plaza Cataluña y de la estación de metro de Urquinaona. Ofrece habitaciones con aire acondicionado y conexión Wi-Fi gratuita.",
      telefon: "938 04 09 88",
      preu: 50,
      icona: "https://www.hostalelscubs.com/wp-content/uploads/2019/03/logo-1.png",
      tipusActivitat: "Hotel",
      web: "https://www.hostalelscubs.com/",
      adreca: "Ctra. Comarcal, 37 Km. 72.8, 08711 Òdena, Barcelona",
    }
 ]

 export const COLUMNS:any = [
   {
     Header: "Name",
     accessor: "Nom",
     className: "text-center ",
   },
   {
     Header: "Price",
     accessor: "Preu",
     className: "text-center ",
   },
   {
     Header: "Quantity",
     accessor: "Quantitat",
     className: "text-center ",
   },
   {
     Header: "Type",
     accessor: "Tipus",
     className: "text-center ",
   },
   {
     Header: "Date",
     accessor: "Data",
     className: "text-center ",
   },
  {
    Header: "Code",
    accessor: "Codi",
    className: "text-center ",
  },
 ];

 export const DATATABLE = [
  {
  Nom: "Pierre",
  Preu: "1€",
  Quantitat: "1",
  Codi: "Nasdfs123",
  Data: "2021-01-01",
  Tipus: "Restauració",
  },
  {
    Nom: "Mondieur",
    Preu: "22.22 €",
    Quantitat: "2",
    Codi: "Nasdfs123",
    Data: "2021-01-02",
    Tipus: "Restauració",
    },
    {
      Nom: "asdf",
      Preu: "223.22 €",
      Quantitat: "3",
      Codi: "Nasdfs123",
      Data: "2021-02-02",
      Tipus: "Producte",
      },
 ];

 export const GlobalFilter = ({ filter, setFilter }:any) => {
   return (
     <span className="d-flex ms-auto">
       <input
         value={filter || ""}
         onChange={(e) => setFilter(e.target.value)}
         className="form-control mb-4"
         placeholder="Search..."
       />
     </span>
   );
 };

