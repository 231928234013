export const DATA_PACK:any = {
    titol : 'Trek & Ride',

    activitats: [
    {
        id: 1,
        titol: "Hostal Abat Cisneros",
        descripcio: "Es un hostal de 3 estrellas situado en el centro de Barcelona, a 5 minutos a pie de la plaza Cataluña y de la estación de metro de Urquinaona. Ofrece habitaciones con aire acondicionado y conexión Wi-Fi gratuita.",
        dia : 1,
        // hora : "12:00",
        quantitat : 1,
    },
    {
        id: 2,
        titol: "Els Cubs",
        dia : 1,
        hora : "15:00",
        descripcio : "Ctra. Comarcal, 37 Km. 72.8, 08711 Òdena, Barcelona",
        quantitat : 1,
    },
    {
        id: 3,
        titol : "Bus Autocars Carrera",
        dia : 2,
        hora : "6:10",
        quantitat : 1,

    },
    {
        id: 4,
        titol : '"Cal Farrés, Calaf"',
        dia : 2,
        descripcio:  "Cal Farrés - Aleny 08281 Calonge de Segarra Barcelona (Alta Anoia)",
        hora : "14:00",
    },
    {
        id: 5,
        titol: "Leather Museum d'Igualada",
        dia : 3,
        descripcio: "Carrer del Dr. Joan Mercader, 1, 08700 Igualada, Barcelona",
        hora : "10:00",
    },
    {
        id: 6,
        titol: "Observatori de Pujalt",
        dia : 4,
        descripcio: "Carretera de l'Astor, S/N, 08282, Pujalt, Barcelona",
    }
]
}