import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Auth from "./Authentication/auth";

import Loader from "./shade/Loaders/Loaders"
import Dashboard from './components/Dashboard/Dashboard';
import LeafletMaps from './components/Maps/LeafletMaps/LeafletMaps';
import App from './App';
import CreateCustomer from './components/Customers/CreateCustomer';
import ManageCustomers from './components/Customers/ManageCustomer';
import AgencySettings from './components/Agency/Agency';
import Joc360 from './components/360/Joc360';
import Notifications from './components/Agency/Notifications/Notifications';
import Rewards from './components/Rewards/Rewards';
import Packs from './components/Pack/Pack';
import DontMissIt from './components/Agency/DontMissIt/dontMissIt';
import UserDocuments from './components/Customers/Documents/Documents';
// import FAQS from './components/Agency/FAQS/FAQS';

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"))
const AuthResetPassword = React.lazy(() => import("./Authentication/ResetPassword"))
//Form
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
  <BrowserRouter>
    <React.Suspense fallback={<Loader/>}>
      <Routes>
        {/* Login Routes */}
        <Route path={`/`} element={<Auth />}>
          <Route index element={<AuthLogin />} />
          <Route
            path={`/authentication/login`}
            element={<AuthLogin />}
          />
          <Route
            path={`/authentication/signup`}
            element={<AuthSignup />}
          />
          <Route
            path={`/authentication/reset-password/:token`}
            element={<AuthResetPassword/>}
          />
        </Route> 
        <Route path={`/`} element={<App />}>
          <Route>
              <Route
                path={`/dashboard/dashboard-1`}
                element={<Dashboard />}
              />
          </Route>
          <Route>
              <Route
                path={`/maps/leafletMaps`}
                element={<LeafletMaps />}
              />
              <Route
                path={`/customer/create`}
                element={<CreateCustomer />}
              />
              <Route
                path={`/customer/manage`}
                element={<ManageCustomers />}
              />
              <Route
                path={`/agency/edit`}
                element={<AgencySettings />}
              />
              <Route
                path={`/joc360`}
                element={<Joc360 />}
              />
              <Route
                path={`/notifications`}
                element={<Notifications/>}
              />
              <Route
                path={`/reward`}
                element={<Rewards/>}
              />
              <Route
                path={`/pack`}
                element={<Packs/>}
              />
              <Route
                path={`/dontMissIt`}
                element={<DontMissIt/>}
              />
              <Route 
                path={'/documents'}
                element={<UserDocuments/>}
              />
              {/* <Route
                path={`${process.env.PUBLIC_URL}/faqs`}
                element={<FAQS/>}
              /> */}
            </Route>
        </Route>
      </Routes>
    </React.Suspense>
  </BrowserRouter>
  </React.Fragment>
);

reportWebVitals();
