import React, { useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Modal,
  Button,
  ProgressBar,
  Form,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import { latLng } from "leaflet";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";
const params = {
  q: "",
  format: "json",
  addressdetails: "addressdetails",
};

export default function SearchBox(setMapPosition:any,setMarkerPosition:any,searchText:any, setSearchText:any) {
  const [listPlace, setListPlace] = useState([]);

  return (
    <div>
      <Row>
        <Col md={8}>
          <input
            type="text"
            className="form-control"
            placeholder="Find a place"
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </Col>
        <Col md={2}>
          <Button
            style={{padding:"5px"}} 
            onClick={() => {
              // Search
              const params: any = { 
                q: searchText,
                format: "json",
                addressdetails: 1,
                polygon_geojson: 0,
                countrycodes: ["es", "fr"], // Only search in Spain and France
              };
              const queryString = new URLSearchParams(params).toString();
              const requestOptions: any = {
                method: "GET",
                redirect: "follow",
              };
              fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  // console.log(JSON.parse(result));
                  setListPlace(JSON.parse(result));
                })
                .catch((err) => console.log("err: ", err));
            }}
          >
            <svg
              width="2rem"
              height="2rem"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3575 10.4704H10.798L10.5996 10.2791C11.4496 9.28745 11.8888 7.93453 11.648 6.49661C11.315 4.52745 9.67171 2.95495 7.68837 2.71411C4.69212 2.34578 2.17046 4.86745 2.53879 7.8637C2.77962 9.84703 4.35212 11.4904 6.32129 11.8233C7.75921 12.0641 9.11212 11.6249 10.1038 10.7749L10.295 10.9733V11.5329L13.3055 14.5433C13.5959 14.8337 14.0705 14.8337 14.3609 14.5433C14.6513 14.2529 14.6513 13.7783 14.3609 13.4879L11.3575 10.4704ZM7.10754 10.4704C5.34379 10.4704 3.92004 9.04661 3.92004 7.28286C3.92004 5.51911 5.34379 4.09536 7.10754 4.09536C8.87129 4.09536 10.295 5.51911 10.295 7.28286C10.295 9.04661 8.87129 10.4704 7.10754 10.4704Z"
                fill="white"
              />
            </svg>
          </Button>
        </Col>
        <Col md={12}>
          <List component="nav" aria-label="main mailbox folders">
            {listPlace.map((place: any) => {
              return (
                <div>
                  <ListItem button onClick={() => { console.log(place);setMarkerPosition(latLng(place.lat,place.lon));setMapPosition(latLng(place.lat,place.lon)); setListPlace([]);}}>
                    <ListItemText primary={place.display_name} />
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        </Col>
      </Row>
    </div>
  );
}
