import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Modal,
  Button,
  ProgressBar,
  Form,
  FormGroup,
  InputGroup,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Polyline,
  Rectangle,
  Polygon,
  Circle,
  Marker,
  useMapEvent,
  useMap,
} from "react-leaflet";
import Select from "react-select";

import { addCoordinateTransforms, fromLonLat } from "ol/proj";
import { icon, LatLng, latLng, map } from "leaflet";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "../../../index.scss";
import src from "react-select/dist/declarations/src";
import Scrollbars from "react-custom-scrollbars";
import { LIST_LATEST_NEWS } from "../../../Data/dataLatestNews";
import { Checkbox } from "@material-ui/core";

const Notifications = () => {
  const [mapPosition, setMapPosition] = React.useState(
    latLng([42.12033, 2.452541])
  );
  const [markerPosition, setMarkerPosition] = React.useState(
    latLng([42.12033, 2.452541])
  );
  const position: any = [51.505, -0.09];
  const redOptions: any = { color: "red" };
  const center: any = [51.51, -0.12];
  const [Fileuploader, setFileuploader] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [Search, setSearch] = React.useState<any>("");
  var [currentNew, setCurrentActivity] = useState(LIST_LATEST_NEWS[0]);

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        setMarkerPosition(e.target.getLatLng());
      },
    }),
    []
  );

  const options = [
    { value: "Vista 360", label: "360º" },
    { value: "Vista dron", label: "Drone" },
    { value: "Vista normal", label: "Normal View" },
  ];

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const RecenterAutomatically = ({ lat, lng }: any) => {
    const map = useMap();
    useEffect(() => {
      if (
        markerPosition.lat == mapPosition.lat &&
        markerPosition.lng == mapPosition.lng
      ) {
        map.setView([lat, lng]);
      }
    }, [lat, lng]);
    return null;
  };

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFileuploader(files);
  }

  // Llista de activitats
  function renderNews(data: any) {
    return data.map((item: any) => {
      return (
        <button
          className="main-contact-item w-100"
          key={item.id}
          onClick={() => setCurrentActivity(item)}
        >
          <div className="main-img-user online">
            <img alt="avatar" src={require("../../../assets/img/point.png")} />
          </div>
          <div className="main-contact-body">
            <h5>
              {item.titol.length > 30
                ? item.titol.substring(0, 30) + "..."
                : item.titol}
            </h5>
          </div>
        </button>
      );
    });
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <h4 className="content-title mb-2">Latest news</h4>
        </div>
        <div className="justify-content-center mt-2">
          <Button
            variant="primary"
            className="btn btn-primary   d-flex align-items-end justify-content-end  "
            onClick={() => {
              setShow(true);
            }}
          >
            + Add News
          </Button>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header
          closeButton
          style={{
            width: "50vw",
            maxHeight: "90vh",
            alignSelf: "center",
            backgroundColor: "white",
          }}
        >
          <Modal.Title>Add new</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            width: "50vw",
            maxHeight: "100vh",
            alignSelf: "center",
            backgroundColor: "white",
          }}
        >
          <Row>
            {/* Segona columna */}

            <Col md={6}>
              <Card.Body>
                <label className="form-label">Icon</label>
                {Fileuploader.length == 0 && Fileuploader != null ? (
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable w-100 h-25 d-flex justify-content-center">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <div className="justify-content-center mt-1 dropzoneicon ">
                            <i className="mdi mdi-apple-mobileme justify-content-center mt-4 mb-1"></i>
                          </div>
                          <p style={{ color: "#9393b5" }}>
                            Drop files here or click to upload.
                          </p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                ) : (
                  <div className="list-unstyled mb-0" id="file-previews">
                    {Fileuploader.map((f: any, i: any) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                              {/* Close delete button */}
                              <Col className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-link font-18 text-muted"
                                  onClick={() => {
                                    let newFileList = [...Fileuploader];
                                    newFileList.splice(i, 1);
                                    setFileuploader(newFileList);
                                  }}
                                >
                                  <i className="dripicons-cross">x</i>
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                )}

                <div className="form-group">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="News title"
                  />
                </div>
              </Card.Body>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label">Description</label>
                <textarea
                  className="form-control mg-t-20"
                  placeholder="Description "
                  required
                  rows={3}
                  defaultValue=""
                ></textarea>
              </div>
              <div className="main-contact-info-body">
                <label className="form-label">Show</label>
                <Checkbox />
              </div>
            </Col>
            <Col
              sm={12}
              className="col-12 justify-content-right padding-bottom-20"
            >
              {/* div flex end */}
              <div className="h-100 d-flex align-items-end justify-content-end">
                <div className="px-4">
                  <Button
                    variant="primary"
                    className="btn btn-primary   d-flex align-items-end justify-content-end  "
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Create news
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* <!-- /breadcrumb --> */}
      <div className="my-auto breadcrumb-right">
        <div className="p-1">
          <Row>
            {/* Row 100 width */}
            <Col sm={12} lg={5} xl={4} xxl={3}>
              <Card className="custom-card">
                <div className="">
                  <div className="main-content-contacts pt-0">
                    <div className="main-content-left main-content-left-contacts slid1">
                      <Nav
                        defaultActiveKey="link-1"
                        className="nav main-nav-line  border-bottom-0 main-nav-line-chat  p-3"
                      >
                        <Nav.Item></Nav.Item>
                      </Nav>
                      <Scrollbars style={{ height: 750 }}>
                        <div
                          className="main-contacts-list"
                          id="mainContactList"
                        >
                          {renderNews(LIST_LATEST_NEWS)}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={12} lg={7} xl={8} xxl={9}>
              {/* Activity form */}
              <div className="">
                <Card className="custom-card">
                  <Card.Body>
                    <Row>
                      <Col sm={12} lg={7} xl={8} xxl={9}>
                        <div className="">
                          <Link
                            className="main-header-arrow"
                            to="#"
                            id="ChatBodyHide"
                          >
                            <i className="icon ion-md-arrow-back"></i>
                          </Link>
                          <div className="main-content-body main-content-body-contacts card custom-card">
                            <div className="main-contact-info-header pt-3">
                              <div className="media">
                                <div className="media-body">
                                  <h2>{currentNew.titol}</h2>
                                </div>
                              </div>
                              <div className="main-contact-action btn-list ">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Edit Customer</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-primary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Edit Profile"
                                  >
                                    <i className="fe fe-edit"></i>
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Delete Customer</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-secondary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Delete Profile"
                                  >
                                    <i className="fe fe-trash-2"></i>
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        {/* Iframe Game */}
                        <div className="main-contact-info-body w-100 h-75">
                          <label className="form-label">Icon</label>
                          <div className="main-contact-info-body">
                            <img
                              src={currentNew.url}
                              alt="icon"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        {/* Latitud longitud */}
                        <Row>
                          <Col lg={12}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Title</label>
                              <p>
                                <strong>{currentNew.titol}</strong>
                              </p>
                            </div>
                          </Col>
                          <Col lg={12}>
                            {/* Adreça */}
                            <div className="main-contact-info-body">
                              <label className="form-label">Description</label>
                              <p>{currentNew.descripcio}</p>
                            </div>
                          </Col>
                          <Col lg={6}>
                            {/* Check list mostrar */}
                            <div className="main-contact-info-body">
                              <label className="form-label">Show</label>
                              <Checkbox
                                disabled={true}
                                checked={currentNew.mostrar}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

Notifications.propTypes = {};

Notifications.defaultProps = {};

export default Notifications;
