import { Select } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Modal,
  Button,
  ProgressBar,
  Form,
  FormGroup,
  InputGroup,
  Nav,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Polyline,
  Rectangle,
  Polygon,
  Circle,
  Marker,
  useMapEvent,
  useMap,
} from "react-leaflet";

import {
  CForm,
  CCol,
  CFormLabel,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CButton,
  CFormCheck,
  CFormSelect,
} from "@coreui/react";
import { ACTIVITY_LIST } from "../../Data/dataActivity";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { ModalActivity } from "./modalActivity";
import { DATA_PACK } from "../../Data/dataPack";
import { AnyNaptrRecord } from "dns";

export const ModalPack = (show: any, setShow: any) => {
  const [currentActivity, setcurrentActivity] = useState<any>({});
  const [Fileuploader, setFileuploader] = React.useState([]);
  const [Fileuploader2, setFileuploader2] = React.useState([]);
  const [Search, setSearch] = React.useState<any>("");
  const [activityPopup, setactivityPopup] = useState<any>(false);
  // const [activity, setactivity] = useState<any>([]);

  const options = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    // { value: 8, label: "8" },
    // { value: 9, label: "9" },
    // { value: 10, label: "10" },
    // { value: 11, label: "11" },
  ];

  function handleAcceptedFiles(files: any,Fileuploader:any,setFileuploader:any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFileuploader(files);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function AddActivity() {
    return DATA_PACK.activitats.map((activity: any) => {
      return (
        <li>
          <div className="notification-time">
            <span className="date">Day {activity.dia}</span>
            <span className="time">{activity.hora}</span>
          </div>
          <div className="notification-icon">
            <Link to="#"></Link>
          </div>
          <div className="notification-body">
            <div className="media mt-0">
              <div className="media-body">
                <div className="d-flex align-items-center">
                  <div className="mt-0">
                    <h5 className="mb-1 tx-15 font-weight-semibold text-dark">
                      {activity.titol}
                    </h5>
                    <p className="mb-0 tx-13 mb-0 text-muted">
                      {activity.descripcio}
                    </p>
                  </div>
                  <div className="ms-auto">
                    <Badge bg="" className="float-end badge notification-badge">
                      <span className="tx-11 font-weight-semibold">
                        Quantity: {activity.quantitat ? activity.quantitat : 1}
                      </span>
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  function renderDropzone(text: String,Fileuploader:any,setFileuploader:any) {
    return (
      <>
        <label className="form-label">{text}</label>
        {Fileuploader.length == 0 && Fileuploader != null ? (
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles,Fileuploader,setFileuploader);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone dz-clickable w-75 h-75 d-flex justify-content-center">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <div className="justify-content-center mt-1 dropzoneicon ">
                    <i className="mdi mdi-apple-mobileme justify-content-center mt-4 mb-1"></i>
                  </div>
                  <p style={{ color: "#9393b5" }}>
                    Drop files here or click to upload.
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
        ) : (
          <div className="list-unstyled mb-0" id="file-previews">
            {Fileuploader.map((f: any, i: any) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      {/* Close delete button */}
                      <Col className="col-auto">
                        <button
                          type="button"
                          className="btn btn-link font-18 text-muted"
                          onClick={() => {
                            let newFileList = [...Fileuploader];
                            newFileList.splice(i, 1);
                            setFileuploader(newFileList);
                          }}
                        >
                          <i className="dripicons-cross">x</i>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        )}
      </>
    );
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      {ModalActivity(
        activityPopup,
        setactivityPopup,
        currentActivity,
        setcurrentActivity
      )}
      <Modal.Header
        closeButton
        style={{
          width: "50vw",
          maxHeight: "90vh",
          alignSelf: "center",
          backgroundColor: "white",
        }}
      >
        <Modal.Title>Add new Pack</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          width: "50vw",
          maxHeight: "100vh",
          alignSelf: "center",
          backgroundColor: "white",
        }}
      >
        <Row>
          <Col lg={6}>
            <div>
              <label className="form-label">Name</label>
              <input type="text" className="form-control" placeholder="Name" />
            </div>
          </Col>
          <Col lg={6}></Col>
          <Col lg={6} className="justify-content-center pb-3">
            {/* Upload track */}
            {renderDropzone("Track",Fileuploader,setFileuploader)}
          </Col>
          <Col lg={6} className="justify-content-center pb-3">
            {/* Upload track */}
            {renderDropzone("Roadbook",Fileuploader2,setFileuploader2)}
          </Col>
          <Col lg={12} className="justify-content-center" />
        </Row>
        {/* <!-- row --> */}
        <div className="container">
          <ul className="notification">{AddActivity()}</ul>
          <div className="text-center mb-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setactivityPopup(true);
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
