import React from "react";
import { Breadcrumb, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const WarningModal = (showWarning: boolean, setSowWarning: any) => {
  return (
    <Modal
      className="bg-transparent"
      show={showWarning}
      onHide={() => setSowWarning(false)}
    >
      <div className="text-warning bg-warning-transparent   bd bd-warning">
        <div className="main-error-wrapper">
          <i className="si si-exclamation mg-b-20 tx-50"></i>
          <h4 className="">Are you sure ?</h4>
          <div className="d-flex d-row justify-content-between">
            <div className="mg-b-20 pr-2">
                <button className="btn btn-outline-danger btn-sm ">Yes</button>
            </div>
            <div className="mg-b-20 ">
                <button className="btn btn-outline-success btn-sm ">No</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
