import React, {Fragment,useEffect} from "react";
// import Header from "../Header/Header";
// import Footer from "../Footer/Footer";
// import Switcher from "../Switcher/Switcher";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import TabToTop from "./shade/TabToTop/TabToTop";
import * as Switcherdata from "./Data/switcherData";

export default function App() {
  //The created store
  useEffect(() => {
    Switcherdata.localStorageBackUp();
  });
  
  document.querySelector("body")?.classList.add("ltr", "main-body", "app", "sidebar-mini");
  document.querySelector("body")?.classList.remove("error-page1", "bg-primary");
  
  function Sidebargone(gone:any) {
    if (gone.matches) { // If media query matches
      document.querySelector("body")?.classList.add("sidebar-gone");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-gone");
      document.querySelector("body")?.classList.remove("sidenav-toggled");
    }
  }

  var gone = window.matchMedia("(max-width: 1024px)")
  Sidebargone(gone) // Call listener function at run time
  gone.addListener(Sidebargone)
  return (
    <Fragment>
          <div className="horizontalMenucontainer">
            <TabToTop />
            <div className="page">
              <div className="open">
                <Sidebar />
              </div>
              <div className="main-content app-content"  onClick={() => {
                      // responsiveSidebarclose();
                    }}>
                <div className="side-app">
                  <div
                    className="main-container container-fluid"
                  >
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
    </Fragment>
  );
}
