// nom varchar(100) 
// recompensa varchar(300) 
// imatge varchar(250) 
// descripcio varchar(400) 
// web varchar(150) 
// codi varchar(100)


export const LIST_REWARDS:any = [
    {
        id: 1,
        nom: "Cal Vilaseca",
        recompensa : "30% OFF",
        imatge: require("../assets/img/6.jpg"),
        descripcio : "L'activitat agrària a la finca de Cal Vilaseca, de La Torre de Claramunt, és l'origen d'una empresa que pretén portar els productes del camp a la taula amb la major qualitat i el menor temps possible.",
        web : "www.calvilaseca.cat",
    },
]