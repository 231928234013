import React, { useEffect,useState } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import "../../index.scss";
import Scrollbars from "react-custom-scrollbars";
import { Modal360 } from "../Modals/360Modal";
import { API_URL } from "../../assets/Constants/constants";
import { IJoc360 } from "../Interfaces/Interfaces";
import { WarningModal } from "../Modals/WarningModal";

const Joc360 = () => { 

  const [show, setShow] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(true);

  const [jocs , setJocs] = useState<IJoc360[]>([]);
  var [currentJoc, setCurrentActivity] = useState<IJoc360>({
    idJoc360: 999999,
    titol: "No activity selected",
    url: "",
    imatgeMiniatura: "",
    idTipus: 0,
    preguntaQuizz: "",
    respostaQuizz: "",
    idRecompensa: 0,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(API_URL+"/joc360" , {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Bearer": "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setJocs(data.rows);
        setCurrentActivity(data.rows[0]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Llista de activitats
  function renderGames(data: IJoc360[]) {
    return data.map((item: IJoc360) => {
      return (
        <button
          className="main-contact-item w-100"
          key={item.idJoc360}
          onClick={() => setCurrentActivity(item)}
        >
          <div className="main-contact-body">
            <h5>{item.titol}</h5>
          </div>
        </button>
      );
    });
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <h4 className="content-title mb-2">360º Game</h4>
        </div>
        <div className="justify-content-center mt-2">
          <Button
            variant="primary"
            className="btn btn-primary   d-flex align-items-end justify-content-end  "
            onClick={() => {
              setShow(true);
            }}
          >
            + Create new 360º
          </Button>
        </div>
      </div>
      
      {/* <!-- /breadcrumb --> */}
      <div className="my-auto breadcrumb-right">
        <div className="p-1">
          <Row>
            {/* Row 100 width */}
            <Col sm={12} lg={5} xl={4} xxl={3}>
              <Card className="custom-card">
                <div className="">
                  <div className="main-content-contacts pt-0">
                    <div className="main-content-left main-content-left-contacts slid1">
                      <Nav
                        defaultActiveKey="link-1"
                        className="nav main-nav-line  border-bottom-0 main-nav-line-chat  p-3"
                      >
                        <Nav.Item></Nav.Item>
                      </Nav>
                      <Scrollbars style={{ height: 750 }}>
                        <div
                          className="main-contacts-list"
                          id="mainContactList"
                        >
                          {renderGames(jocs)}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={12} lg={7} xl={8} xxl={9}>
              {/* Activity form */}
              <div className="">
                <Card className="custom-card">
                  <Card.Body>
                    <Row>
                      <Col sm={12} lg={7} xl={8} xxl={9}>
                        <div className="">
                          <Link
                            className="main-header-arrow"
                            to="#"
                            id="ChatBodyHide"
                          >
                            <i className="icon ion-md-arrow-back"></i>
                          </Link>
                          <div className="main-content-body main-content-body-contacts card custom-card">
                            <div className="main-contact-info-header pt-3">
                              <div className="media">
                                <div className="media-body">
                                  <h2>{currentJoc.titol}</h2>
                                </div>
                              </div>
                              <div className="main-contact-action btn-list ">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Edit Game</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-primary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Edit Profile"
                                  >
                                    <i className="fe fe-edit"></i>
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Delete Game</Tooltip>}
                                  
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-secondary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Delete Profile"
                                  >
                                    <i className="fe fe-trash-2"></i>
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} >
                        {/* Iframe Game */}
                        <div className="main-contact-info-body w-100 h-75">
                          <label className="form-label">Iframe</label>
                          <iframe src={currentJoc.url} width="100%" height="100%"></iframe>
                        </div>

                      </Col>  
                      <Col lg={6}>
                        {/* Latitud longitud */}
                        <Row>
                          <Col lg={6}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Title</label>
                              <p><strong>{currentJoc.titol}</strong></p>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Type</label>
                              <p>{currentJoc.idTipus}</p>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Web</label>
                              <p>{currentJoc.url}</p>
                            </div>
                          </Col>
                          <Col lg={12}>
                            {/* Adreça */}
                            <div className="main-contact-info-body">
                              <label className="form-label">Quizz</label>
                              <p>{currentJoc.preguntaQuizz}</p>
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="main-contact-info-body">
                              <label className="form-label">Answer</label>
                              <p>{currentJoc.respostaQuizz}</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {Modal360(show,setShow)}
      {WarningModal(showWarning, setShowWarning)}
    </div>
  );
};

Joc360.propTypes = {};
Joc360.defaultProps = {};
export default Joc360;
