
 export const LIST_LATEST_NEWS:any = [
    {
        id: 1,
        titol: "Ap7 has traffic restrictions",
        url: require("../assets/img/6.jpg"),
        descripcio : "Ap7 has traffic restrictions",
        mostrar: true
    },
    {
        id: 2,
        titol : "Dont forget to you leave the bikes locked in the carpark of the hotel,and the GPS at reception",
        descripcio : "Dont forget to you leave the bikes locked in the carpark of the hotel,and the GPS at reception",
        url: require("../assets/img/6.jpg"),
        mostrar: true
    }
]