import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Modal,
  Button,
  ProgressBar,
  Form,
  FormGroup,
  InputGroup,
  Nav,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Polyline,
  Rectangle,
  Polygon,
  Circle,
  Marker,
  useMapEvent,
  useMap,
} from "react-leaflet";
import Select from "react-select";

import { addCoordinateTransforms, fromLonLat } from "ol/proj";
import { icon, LatLng, latLng, map } from "leaflet";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "../../index.scss";
import src from "react-select/dist/declarations/src";
import Scrollbars from "react-custom-scrollbars";
import { LIST_REWARDS } from "../../Data/dataRewards";
import { Accept } from "react-dropzone";
import { ModalActivity } from "../Modals/modalActivity";
import { ModalPack } from "../Modals/modalPack";
import { DATA_PACK } from "../../Data/dataPack";

const Packs = () => {
  const [mapPosition, setMapPosition] = React.useState(
    latLng([42.12033, 2.452541])
  );
  const [markerPosition, setMarkerPosition] = React.useState(
    latLng([42.12033, 2.452541])
  );
  const position: any = [51.505, -0.09];
  const redOptions: any = { color: "red" };
  const center: any = [51.51, -0.12];
  const [Fileuploader, setFileuploader] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [Search, setSearch] = React.useState<any>("");
  const [activityPopup, setActivityPopup] = React.useState(false);
  const [activityDays, setActivityDays] = React.useState<any>([]);
  const [currentActivity, setCurrentActivity] = React.useState<any>({});

  var [currentReward, setCurrentReward] = useState(LIST_REWARDS[0]);

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        setMarkerPosition(e.target.getLatLng());
      },
    }),
    []
  );

  const options = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    // { value: 8, label: "8" },
    // { value: 9, label: "9" },
    // { value: 10, label: "10" },
    // { value: 11, label: "11" },
  ];

  function AddActivity() {
    return DATA_PACK.activitats.map((activity: any) => {
      return (
        <li>
          <div className="notification-time">
            <span className="date">Day {activity.dia}</span>
            <span className="time">{activity.hora}</span>
          </div>
          <div className="notification-icon">
            <Link to="#"></Link>
          </div>
          <div className="notification-body">
            <div className="media mt-0">
              
              <div className="media-body">
                <div className="d-flex align-items-center">
                  <div className="mt-0">
                    <h5 className="mb-1 tx-15 font-weight-semibold text-dark">
                      {activity.titol}
                    </h5>
                    <p className="mb-0 tx-13 mb-0 text-muted">
                      {activity.descripcio}
                    </p>
                  </div>
                  <div className="ms-auto">
                    <Badge bg="" className="float-end badge notification-badge">
                      <span className="tx-11 font-weight-semibold">
                        Quantity: {activity.quantitat ? activity.quantitat : 1}
                      </span>
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const RecenterAutomatically = ({ lat, lng }: any) => {
    const map = useMap();
    useEffect(() => {
      if (
        markerPosition.lat == mapPosition.lat &&
        markerPosition.lng == mapPosition.lng
      ) {
        map.setView([lat, lng]);
      }
    }, [lat, lng]);
    return null;
  };

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFileuploader(files);
  }

  // Llista de activitats
  function renderRewards(data: any) {
    return data.map((item: any) => {
      return (
        <button
          className="main-contact-item w-100"
          key={item.id}
          onClick={() => setCurrentReward(item)}
        >
          <div className="main-contact-body">
            <h5>{DATA_PACK.titol}</h5>
          </div>
        </button>
      );
    });
  }

  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <h4 className="content-title mb-2">Rewards</h4>
        </div>
        <div className="justify-content-center mt-2">
          <Button
            variant="primary"
            className="btn btn-primary   d-flex align-items-end justify-content-end  "
            onClick={() => {
              setShow(true);
            }}
          >
            + Create new Pack
          </Button>
        </div>
      </div>
      {ModalPack(show, setShow)}

      {/* <!-- /breadcrumb --> */}
      <div className="my-auto breadcrumb-right">
        <div className="p-1">
          <Row>
            {/* Row 100 width */}
            <Col sm={12} lg={5} xl={4} xxl={3}>
              <Card className="custom-card">
                <div className="">
                  <div className="main-content-contacts pt-0">
                    <div className="main-content-left main-content-left-contacts slid1">
                      <Nav
                        defaultActiveKey="link-1"
                        className="nav main-nav-line  border-bottom-0 main-nav-line-chat  p-3"
                      >
                        <Nav.Item></Nav.Item>
                      </Nav>
                      <Scrollbars style={{ height: 750 }}>
                        <div
                          className="main-contacts-list"
                          id="mainContactList"
                        >
                          {renderRewards(LIST_REWARDS)}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={12} lg={7} xl={8} xxl={9}>
              {/* Activity form */}
              <div className="">
                <Card className="custom-card">
                  <Card.Body>
                    <Row>
                      <Col sm={12} lg={7} xl={8} xxl={9}>
                        <div className="">
                          <Link
                            className="main-header-arrow"
                            to="#"
                            id="ChatBodyHide"
                          >
                            <i className="icon ion-md-arrow-back"></i>
                          </Link>
                          <div className="main-content-body main-content-body-contacts card custom-card">
                            <div className="main-contact-info-header pt-3">
                              <div className="media">
                                <div className="media-body">
                                  <h2>{DATA_PACK.titol}</h2>
                                </div>
                              </div>
                              <div className="main-contact-action btn-list ">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Edit Customer</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-primary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Edit Profile"
                                  >
                                    <i className="fe fe-edit"></i>
                                  </Link>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Delete Customer</Tooltip>}
                                >
                                  <Link
                                    to="#"
                                    className="btn ripple btn-secondary text-white btn-icon"
                                    data-placement="top"
                                    data-bs-toggle="tooltip"
                                    title="Delete Profile"
                                  >
                                    <i className="fe fe-trash-2"></i>
                                  </Link>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        {/* Iframe Game */}
                        <div className="main-contact-info-body">
                          <label className="form-label">Title</label>
                          <p>
                            <strong>{DATA_PACK.titol}</strong>
                          </p>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="container">
                          <ul className="notification">{AddActivity()}</ul>
                          <div className="text-center mb-4">
                            <Button
                              className="btn btn-primary"
                              onClick={() => {
                                console.log('====================================');
                                console.log('Edit Activity');
                                console.log('====================================');
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

Packs.propTypes = {};

Packs.defaultProps = {};

export default Packs;
